@import "styles/variables.scss";

.dashboard-layout-top {
  position: sticky;
  top: 0;
  z-index: 100;
  width: calc(100vw - 295px);
  background-color: #fff;

  @media (max-width: 1300px) {
    width: 100%;
  }

  &__wrapper {
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border-top-left-radius: 50px;
    position: relative;
    z-index: 11;
    gap: 15px;
    background-color: #fff;
    width: 100%;
    border-bottom: 1px solid $color-grey;
    height: 135px;

    @media (max-width: 1300px) {
      padding: 0 20px;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      background-color: #F7F9FA;
      border-bottom: none;
      height: 88px;
    }

    @media (max-width: 1060px) {
      flex-direction: row-reverse;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 60px;
    flex: 1;

    @media (max-width: 1060px) {
      flex: unset;
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 32px;

      @media (max-width: 1060px) {
        display: none;
      }
    }

    .icon-button {
      display: none;
    }

    @media (max-width: 1300px) {
      gap: 20px;

      .icon-button {
        display: flex;
      }
    }

    @media (max-width: 1300px) {
      .button {
        display: none;
      }
    }

    h1 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: 1300px) {
        font-size: 18px;
      }

      @media (max-width: 1060px) {
        display: none;
      }
    }
  }

  &__aside {
    display: flex;
    align-items: center;

    &__buttons {
      margin-right: 30px;
      padding-right: 30px;
      border-right: 1px solid $color-grey;
      display: flex;
      align-items: center;
      gap: 20px;

      @media (max-width: 1060px) {
        display: none;
      }

      .mobile-header-menu {
        display: none;
      }

      @media (max-width: 1300px) {
        .mobile-header-menu {
          display: block;
        }
      }

      @media (max-width: 1300px) {
        margin-right: 15px;
        padding-right: 15px;
      }
    }
  }
}
