.new-project-content__summary{
    display: flex;
    flex-direction: column;
    position: relative;

    &__content{
        display: flex;

        @media (max-width: 830px) {
            flex-direction: column;
            gap: 40px;
        }
    }

    &__left{
        flex: 1;

        h3{
            margin-bottom: 32px;
        }

        &__items{
            display: flex;
            flex-wrap: wrap;
            gap: 24px;

            &__item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px;
                background-color: #0F0FFF;
                max-width: 304px;
                width: 100%;
                border-radius: 5px;

                span{
                    color: #fff;
                    font-weight: 600;
                }

                button{
                    display: flex;
                }
            }
        }
    }

    &__right{
        flex: 1;

        h3{
            margin-bottom: 32px;
        }

        .textarea__content{
            height: 548px;
        }
    }


    &__bottom {
        margin-top: 22px;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 1001;
      }
}