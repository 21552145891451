.mobile-table-list{
  display: flex;
  flex-direction: column;
  gap: 15px;

  .details-card{

    &__header{
      margin-bottom: 24px;
    }

    &__content{
      margin-top: 0;
    }
  }

  &__row{
    &--disabled{
      color: #848484;
      
      .details-card{
        &__list{
          &__item{
            label, span{
              color: #848484;
            }
          }
        }
      }
    }
  }
}