@import "styles/variables.scss";

.details-card-credit-card {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__name {
    display: flex;
    align-items: center;
    gap: 5px;

    &__status {
      margin-top: -15px;
      padding: 0 5px;
      font-size: 9px;
      font-weight: 600;
      text-transform: uppercase;
      background-color: $color-green;
      color: #fff;
      border-radius: 2px;

      &--active {
        background-color: $color-green;
      }

      &--unactive {
        background-color: $color-red;
      }

      &--warning{
        background-color: #DECD00;
      }
    }
  }
}
