@font-face {
  font-family: "area-normal";
  src: url("../assets/fonts/AreaNormal-Regular.otf") format("opentype"),
    url("../assets/fonts/AreaNormal-Regular.woff") format("woff"),
    url("../assets/fonts/AreaNormal-Regular.woff2") format("woff2");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  ascent-override: 100%;
}

@font-face {
  font-family: "area-normal";
  src: url("../assets/fonts/AreaNormal-Semibold.otf") format("opentype"),
    url("../assets/fonts/AreaNormal-Semibold.woff") format("woff"),
    url("../assets/fonts/AreaNormal-Semibold.woff2") format("woff2");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  ascent-override: 100%;
}

@font-face {
  font-family: "area-normal";
  src: url("../assets/fonts/AreaNormal-Black.otf") format("opentype"),
    url("../assets/fonts/AreaNormal-Black.woff") format("woff"),
    url("../assets/fonts/AreaNormal-Black.woff2") format("woff2");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
  ascent-override: 100%;
}