@import "styles/variables.scss";

.task-conversation__status {
  &__content {
    padding: 16px 24px;
    background-color: #fff;
    border: 1px solid #e1e6eb;
    border-radius: 5px;

    &--hightlighted{
      background-color: rgba(#0F0FFF, 0.05);
      border-color: rgba(#0F0FFF,0.3);

    }

    p {
      line-height: 22px;
      font-size: 14px;

      @media (max-width: 1060px) {
        text-align: center;
      }
    }
  }
}
