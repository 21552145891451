@import "styles/variables.scss";

.new-website-content__choose-plan__change-info {
  &__content {
    &__plans {
      display: flex;
      padding-bottom: 30px;
      justify-content: space-between;
      position: relative;
      // border-bottom: 1px solid #e1e6eb;

      &::before {
        content: "";
        position: absolute;
        top: 33px;
        height: 1px;
        width: 100%;
        background-color: #e1e6eb;
      }

      &__item {
        display: flex;
        flex-direction: column;

        label {
          margin-bottom: 30px;
          font-size: 14px;
        }

        span {
          font-weight: 800;
        }

        small {
          font-size: 12px;
        }

        a {
          margin-top: 10px;
          font-size: 12px;
          font-weight: 600;
          color: $primary-color;
        }

        &--arrow {
          padding-top: 60px;
        }
      }
    }

    &__date {
      padding-top: 10px;
      display: flex;
      flex-direction: column;

      small {
        &:first-child {
          font-size: 12px;
        }
      }

      span {
        margin-top: 3px;
        font-size: 16px;
        font-weight: 800;
      }
    }
  }
}
