.tracker-button {
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-color: #00d621;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter ease 0.2s;
    flex-shrink: 0;

    &:hover {
      filter: brightness(1.1);
    }
  }

  span{
    line-height: unset;
  }

  &--active {
    button {
      background-color: #ff0000;
    }
  }
}
