@import "styles/variables.scss";

.send-notification {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  position: absolute;

  .modal__content {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  .select {
    &:nth-of-type(1) {
      z-index: 20 !important;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    color: #333333;
    gap: 16px;

    &::before {
      content: "";
      background-color: #f7f9fa;
      border: 1px solid #ebeef0;
      border-radius: 50%;
      height: 24px;
      width: 24px;

      background-repeat: no-repeat;
      background-position: center center;
    }

    &--active {
      &::before {
        background-image: url("/assets/icons/checkmark.svg");
      }
    }
  }
}
