@import "styles/variables.scss";

.pill {
  padding: 0 16px;
  height: 36px;
  background-color: #f7f9fa;
  border: 1px solid #ebeef0;
  border-radius: 80px;
  color: #848484;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: filter ease 0.2s;
  white-space: nowrap;
  gap: 8px;

  @media (max-width: 930px) {
    padding: 0 24px;
    font-size: 16px;
  }

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: #fff;
    }
  }

  &:hover {
    background-color: #848484;
    border-color: #848484;
    color: #fff;
    text-decoration: unset;
  }

  &--active {
    background-color: #848484;
    border-color: #848484;
    color: #fff;
  }

  &--read-only {
    pointer-events: none;
  }

  &--small {
    height: 30px;
  }

  &--icon {
    height: 20px;
    width: 20px;
    padding: 0

  }

  &--awaiting_estimation {
    background-color: #de009a;
    border-color: #de009a;
    color: #fff;

    &:hover {
      background-color: #de009a;
      border-color: #de009a;
      filter: brightness(1.1);
    }
  }

  &--awaiting_your_action,
  &--high {
    background-color: #decd00;
    border-color: #decd00;
    color: #fff;

    &:hover {
      background-color: #decd00;
      border-color: #decd00;
      filter: brightness(1.1);
    }
  }

  &--accepted_to_realization,
  &--normal {
    background-color: #00ded5;
    border-color: #00ded5;
    color: #fff;

    &:hover {
      background-color: #00ded5;
      border-color: #00ded5;
      filter: brightness(1.1);
    }
  }

  &--in_progress {
    background-color: #9ade00;
    border-color: #9ade00;
    color: #fff;

    &:hover {
      background-color: #9ade00;
      border-color: #9ade00;
      filter: brightness(1.1);
    }
  }

  &--awaiting_verification {
    background-color: #2b2b2b;
    border-color: #2b2b2b;
    color: #fff;

    &:hover {
      background-color: #2b2b2b;
      border-color: #2b2b2b;
      filter: brightness(1.2);
    }
  }

  &--closed {
    background-color: #09de00;
    border-color: #09de00;
    color: #fff;

    &:hover {
      background-color: #09de00;
      border-color: #09de00;
      filter: brightness(1.1);
    }
  }

  &--cancelled,
  &--urgent {
    background-color: #de1100;
    border-color: #de1100;
    color: #fff;

    &:hover {
      background-color: #de1100;
      border-color: #de1100;
      filter: brightness(1.1);
    }
  }
}
