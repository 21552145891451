.invoices-content {
  $self: &;

  .calendar {
    &:last-child {
      @media (max-width: 670px) {
        .calendar__content {
          &--months {
            right: 0;
            left: unset;

            .dropdown-card {
              &::before {
                left: unset;
                right: 10px;
              }
            }
          }
        }
      }

      @media (max-width: 800px) {
        .calendar__content {
          &--days {
            right: 0;
            left: unset;

            .dropdown-card {
              &::before {
                left: unset;
                right: 10px;
              }
            }
          }
        }
      }
    }

    // &__content {

    // }
  }
}
