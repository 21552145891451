@import "styles/variables.scss";

.mobile-menu__websites{
    display: flex;
    flex-direction: column;

    > span{
        font-weight: 600;
    }

    &__items{
        margin: 24px 0 40px;
        display: flex;
        flex-direction: column;

        &__item{
            display: flex;
            align-items: center;
            height: 40px;

            &.wrong {
                &::before {
                  content: "";
                  margin-right: 8px;
                  height: 10px;
                  width: 10px;
                  background-color: $color-red;
                  border-radius: 50%;
                  flex-shrink: 0;
                }
              }
    
              &.verification {
                &::before {
                  content: "";
                  margin-right: 8px;
                  height: 10px;
                  width: 10px;
                  background-color: $primary-color;
                  border-radius: 50%;
                  flex-shrink: 0;
                }
              }
    
              &.disabled{
                opacity: 0.5;
    
                &::before {
                  background-color: #DECD00;
                }
              }
    
              &::before {
                content: "";
                margin-right: 8px;
                height: 10px;
                width: 10px;
                background-color: $color-green;
                border-radius: 50%;
                flex-shrink: 0;
              }
        }
    }
}