.priority-flag {
  display: flex;

  &--high {
    svg {
      path {
        fill: #decd00;
      }
    }
  }

  &--urgent {
    svg {
      path {
        fill: #de1100;
      }
    }
  }
}
