@import "styles/variables.scss";

.breadcrumps{
  display: flex;
  align-items: center;

  @media (max-width: 1060px) {
    display: none;
  }

  &.extra_padding{
    padding-left: 16px;
  }

  > div{
    display: flex;
    align-items: center;

    &:last-of-type{
      small{
        display: none;
      
      }
    }
  }

  small{
    padding: 0 5px;
  }

  span{
    color: $primary-color;
  }

  a,span, small{
    font-size: 12px;
    line-height: 20px;

    &:first-child{
      &::before{
        display: none;
      }
    }

  }

  &__skeleton {
    border-radius: 20px;
    height: 10px;
    width: 50px;
    display: flex;
    align-items: center;
    background: linear-gradient(to right, #f7f9fa, #eaeaea 50%, #f7f9fa 80%),
      #f7f9fa;
    background-repeat: repeat-y;
    background-size: 50px 100%;
    background-position: -50px 0;
    animation: shimmer 1s infinite;
  }
}

@keyframes shimmer {
  to {
    background-position: calc(100% + 50px) 0;
  }
}
