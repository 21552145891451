.dashboard-tasks-action {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 32px;
  flex: 1;

  &__item {
    padding: 24px;
    padding-top: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f7f9fa;
    border-radius: 5px;
    text-align: center;

    strong {
      margin-bottom: 7px;
      font-weight: 600;
    }

    span {
      margin-bottom: 33px;
    }
  }
}
