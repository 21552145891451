.task-conversation__message__content__attachments {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;

  &--edit-open{
    margin-top: 20px;
  }

  .ReactModal__Body--open{
    z-index: 1103 !important;
  }

  &__gallery{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    grid-gap: 5px;
  
    &__item{
      aspect-ratio: 1/1;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      position: relative;
  
      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &__links{
    display: flex;
    flex-direction: column;
    gap: 5px;

    a {
      font-size: 14px;
      font-weight: 600;
      color: #2222cc;
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}