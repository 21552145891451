.page-loader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--panel {
    left: 280px;

    @media (max-width: 1300px) {
      left: 0;
    }
  }
}
