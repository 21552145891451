.new-task-content {
  max-width: 1550px;
  min-height: calc(100% - 12px);
  display: flex;
  flex-direction: column;

  @media (max-width: 1060px) {
    margin-bottom: 80px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .new-task-content__form__content {
    $self: &;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    &__wrapper {
      display: flex;

      @media (max-width: 970px) {
        flex-direction: column;
        gap: 40px;
      }
    }

    &__form {
      flex: 1;

      #description_select{
        .textarea{
          margin-top: 24px;
        }
      }

      @media (max-width: 1060px) {
        // border: 1px solid #E1E6EB;
        // border-radius: 5px;
      }

      @media (max-width: 970px) {
        margin-bottom: 0px;

        &:last-child {
          #{ $self }__form__header {
            display: none;
          }
        }
      }

      &__header {
        height: 32px;
        margin-bottom: 16px;

        @media (max-width: 970px) {
          margin-bottom: 0;
        }
      }

      &__onboarding__wrapper{
        padding: 16px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        position: relative;

        @media (max-width: 1060px) {
          padding: 0;
        }

        &--level{
          padding: 0;
        }
      }
    }

    .form__required-info{
      padding-left: 16px;

      @media (max-width: 1060px) {
        display: none;
      }

    }

    &__able-top-change-info {
      margin-top: 24px;
      margin-bottom: auto;

      small {
        color: #848484;
      }
    }

    &__bottom {
      padding: 10px 0;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      background-color: #fff;

      @media (max-width: 1060px) {
        display: none;
      }

      &--mobile{
        display: flex;
        gap: 16px;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 96px;
        background-color: #fff;
        padding: 10px 16px;

        .button{
          flex: 1;
        }
      }
    }
  }
}
