.create-task-modal{
    .form__input-group{
        gap: 24px;

        &:first-child{
            flex: none;
            max-width: 384px;
            width: 100%;
        }

        .form__input-group{
            gap: 0;
        }
    }
}