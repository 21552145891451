@import "styles/variables.scss";

.dashboard-layout-sidebar {
  $self: &;
  padding: 40px 20px 24px 20px;
  height: 100vh;
  width: 285px;
  background-color: #f7f9fa;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: auto;
  transition: transform ease 0.3s;
  scrollbar-width: thin;
  scrollbar-color: #BFBFBF #ebeef0;

      /* Chrome, Edge and Safari */
      &::-webkit-scrollbar {
        width: 10px;
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #ebeef0;
      }
  
      &::-webkit-scrollbar-track:hover {
        background-color: #ebeef0;
      }
  
      &::-webkit-scrollbar-track:active {
        background-color: #ebeef0;
      }
  
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #BFBFBF;
      }
  
      &::-webkit-scrollbar-thumb:hover {
        background-color: #BFBFBF;
      }
  
      &::-webkit-scrollbar-thumb:active {
        background-color: #BFBFBF;
      }

  @media (max-width: 1300px) {
    position: fixed;
    left: 0;
    bottom: 0;
    height: unset;
    transform: translateX(-100%);
    z-index: 1000;
  }

  > svg {
    flex-shrink: 0;
    width: 173px;
    height: 34px;

    @media (max-width: 930px) {
      width: 155px;
      height: 30px;
    }
  }
}
