@import "styles/variables.scss";

.details-card-status {
  display: flex;
  align-items: center;

  &::before {
    content: "";
    margin-top: 2px;
    margin-right: 8px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &--good {
    &::before {
      background: rgba($color-green, 1);
      box-shadow: 0 0 0 0 rgba($color-green, 1);
      animation: pulse-green 2s infinite;
    }
  }

  &--verification {
    &::before {
      background: rgba($primary-color, 1);
      box-shadow: 0 0 0 0 rgba($primary-color, 1);
      animation: pulse-blue 2s infinite;
    }
  }

  &--wrong {
    &::before {
      background: rgba($color-red, 1);
      box-shadow: 0 0 0 0 rgba($color-red, 1);
      animation: pulse-red 2s infinite;
    }
  }
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-green, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($color-green, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-green, 0);
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-red, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($color-red, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-red, 0);
  }
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($primary-color, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($primary-color, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($primary-color, 0);
  }
}
