.time-over__tracked{
  flex: 1;
  &__card{
    max-width: 768px;
    width: 100%;

    @media (max-width: 1080px) {
      max-width: unset;
    }
  }

  &__time-summary{
    display: flex;

    &__col{
      display: flex;
      flex-direction: column;
      max-width: 160px;
      width: 100%;

      span{
        &:first-child{
          margin-bottom: 10px;
          font-weight: 600;
        }
      }

      &:last-child{
        span{
          &:last-child{
            color: red;
          }
        }
      }
    }
  }

  &__time-entries{
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    &__item{
      padding: 16px 0;
      border-top: 1px solid #E1E6EB;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      &--over{
        color: red;
      }
    }
  }
}