.invoices-content {

  &__filter-wrap{
    display: flex;
    flex-direction: column;
    gap: 36px;
    width: 100%;

    @media (max-width: 1060px) {
      gap: 16px;
    }
  }

  &__filter-row{
    display: flex;
    flex-wrap: wrap;
    gap: 54px;
    row-gap: 36px;

    @media (max-width: 1060px) {
      gap: 16px;
    }
  }
}
