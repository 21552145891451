@import "styles/variables.scss";
@import "styles/fonts.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "area-normal", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $primary-bg;

  @media (min-width: 1300px) {
    padding-bottom: 0 !important;
  }
}

input,
textarea {
  outline-width: 0;
  font-family: "area-normal", sans-serif;
  color: $text-color;
  font-size: 14px;

  @media (max-width: 1060px) {
    font-size: 16px;
  }

  &::placeholder {
    color: $text-color-light;
  }
}

button {
  border: none;
  background-color: transparent;
  font-family: "area-normal", sans-serif;
  cursor: pointer;
  color: #ececf1;
  font-weight: 14px;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

h1 {
  font-size: 41px;
  line-height: 56px;
  color: $text-color;
  font-weight: 800;

  @media (max-width: 930px) {
    font-size: 36px;
    line-height: 51px;
  }

  @media (max-width: 1060px) {
    font-size: 28px;
    line-height: 36px;
  }
}

h2 {
  font-size: 18px;
  line-height: 25px;
  color: $text-color;
  font-weight: 800;
}

h3 {
  font-size: 22px;
  line-height: 32px;
}

p,
span,
label,
a {
  color: $text-color;
  font-size: 16px;
  line-height: 28px;
  text-decoration: none;

  // @media (max-width: 930px) {
  //   font-size: 14px;
  //   line-height: 21px;
  // }
}

a {
  &:hover {
    text-decoration: underline;
  }
}

.root {
  min-height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 1060px) {
    gap: 16px;
  }

  &__group {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    @media (max-width: 1060px) {
      gap: 16px;
    }

    @media (max-width: 520px) {
      flex-direction: column;
    }

    > div {
      flex: 1;
    }
  }

  &__input-group {
    display: flex;
    flex-direction: column;

    > span {
      display: flex;
      margin-bottom: 12px;
      color: $text-color;
      font-size: 14px;
      font-weight: 600;

      > span {
        color: $primary-color;
        margin-bottom: 0;
      }
    }

    &__inputs {
      display: flex;
      align-items: center;
      gap: 8px;

      > span {
        margin-bottom: 0;
        line-height: 10px;
      }
    }
  }

  &__required-info {
    small {
      color: #848484;
    }

    span {
      color: #0f0fff;
    }
  }

  &__error {
    display: block;
    margin-top: 10px;
    color: $color-red;
    font-size: 13px;
  }

  .button {
    margin-top: 5px;
  }
}

.column-layout {
  $self: &;
  display: flex;
  gap: 32px;

  @media (max-width: 1060px) {
    gap: 15px;
  }

  @media (max-width: 980px) {
    flex-direction: column;

    #{ $self }__col {
      max-width: unset;

      &:last-child {
        max-width: unset;
      }
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 560px;
    flex: 1;

    @media (max-width: 1060px) {
      gap: 15px;
    }

    &:last-child {
      max-width: 600px;
    }
  }
}
