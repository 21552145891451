.task-conversation__details {
  padding: 24px;
  max-width: 568px;
  width: 100%;
  background-color: #f7f9fa;
  border: 1px solid #e1e6eb;
  border-radius: 5px;
  height: 100%;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #BFBFBF #ebeef0;

      /* Chrome, Edge and Safari */
      &::-webkit-scrollbar {
        width: 10px;
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #ebeef0;
      }
  
      &::-webkit-scrollbar-track:hover {
        background-color: #ebeef0;
      }
  
      &::-webkit-scrollbar-track:active {
        background-color: #ebeef0;
      }
  
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #BFBFBF;
      }
  
      &::-webkit-scrollbar-thumb:hover {
        background-color: #BFBFBF;
      }
  
      &::-webkit-scrollbar-thumb:active {
        background-color: #BFBFBF;
      }

  @media (max-width: 970px) {
    max-width: unset;
  }

  &__header {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__aside {
      display: flex;
      align-items: center;
      gap: 23px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 12px;

      strong {
        font-weight: 600;
      }

      &__group {
        display: flex;
        gap: 83px;
      }
    }
  }

  &__action {
    margin-top: 24px;

    span {
      font-weight: 600;
    }

    &__buttons {
      margin-top: 24px;
      display: flex;
      gap: 32px;
      row-gap: 15px;
      flex-wrap: wrap;
    }
  }

  > small {
    display: block;
    margin-top: 40px;
    font-size: 12px;
  }

  &--admin-mode {
    background-color: #fff;
  }
}
