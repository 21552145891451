.new-website-content {
  max-width: 1550px;
  min-height: calc(100% - 180px);
  display: flex;
  flex-direction: column;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
