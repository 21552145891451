@import "styles/variables.scss";

.details-change-plan-preview {
  display: flex;
  align-items: center;
  gap: 20px;

  svg {
    width: 23px;
    height: 12px;
  }
}
