@import "styles/variables.scss";

.calendar-months {
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    gap: 15px;

    span {
      font-weight: 600;
    }

    button {
      height: 20px;
      width: 20px;

      &:last-child {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 80px);
    justify-content: center;
    gap: 10px;

    .button {
      border: none;
      text-transform: none;
      color: $text-color;
      width: 100%;

      &:disabled{
        opacity: 0.4;
        background-color: transparent;
        text-decoration:line-through
      }
    }
  }
}
