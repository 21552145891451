@import "styles/variables.scss";


.edit-task-modal{
    display: flex;
    flex-direction: column;

    &__info{
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;

        > span{
            margin-bottom: 10px;
            font-weight: 600;
        }

        &__item{
            display: flex;
            align-items: center;
            justify-content: space-between;

            span{
                font-weight: 400;

                a{
                    color: $primary-color;
                }
            }
        }
    }

    &__content{
        display: flex;
    gap: 32px;

    }

    &__col{
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}