.dashboard-content {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 1060px) {
    gap: 15px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    gap: 32px;

    @media (max-width: 1060px) {
      gap: 15px;
    }

    @media (max-width: 1520px) {
      // flex-direction: column;

    .details-card{
        max-width: unset !important;
        flex: 1;
      }
    }

    .details-card{
      max-width: 568px;
      width: 100%;
      align-self: stretch;


    }

    .table{
      width: 100%;
    }
  }
}
