@import "styles/variables.scss";

.modal {
  $self: &;
  padding: 15px;
  position: fixed;
  top: 134px;
  right: 0;
  bottom: 0;
  left: 280px;
  overflow-y: auto;
  z-index: 10;
  border-bottom-left-radius: 50px;

  &--open{
    #{ $self }__wrapper {
      transform: translateY(0%);
    }

    #{ $self }__backdrop {
      opacity: 1;
    }
  }
  
  &__backdrop{
    position: fixed;
    top: 134px;
    right: 0;
    bottom: 0;
    left: 280px;
    z-index: 11;
    background-color: rgba($text-color, 0.4);
    transition: all ease 0.3s;

    @media (max-width: 1060px) {
      top: 0;
      left: 0;
      opacity: 0;
    }
  }

  @media (max-width: 1300px) {
    top: 80px;
    left: 0;
    z-index: 1100;
    padding: 0;
    border-bottom-left-radius: 0;
  }

  @media (max-width: 620px) {
    top: 65px;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    @media (max-width: 1300px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &__wrapper {
    position: relative;
    padding: 24px;
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    border-radius: 5px;
    z-index: 12;
    transition: all ease 0.3s;

    @media (max-width: 1060px) {
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 32px;
      max-width: unset;
      position: fixed;
      left: 0;
      top: 0;
      overflow: auto;
      padding-bottom: 0;
      max-height: calc(100vh - 100px);
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      transform: translateY(-100%);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;

    @media (min-width: 1060px) {
      .icon-button{
        display: none;
      }
    }

    @media (max-width: 1060px) {
      padding-bottom: 0;


    }

    h3{
      color: #333333;
      display: flex;
      align-items: center;
      gap: 16px;


      svg{
        transform: rotate(180deg);
        height: 20px;
        // width: 6px;
        flex-shrink: 0;

        path{
          stroke: #0F0FFF;
          stroke-width: 3px;
        }
      }

      @media (max-width: 1060px) {
        font-size: 16px;
        font-weight: 600;
      }
      
    }

    button,
    span {
      color: #ececf1;
      font-size: 16px;
      font-weight: 600;
    }
  }

  &__content {
    flex: 1;

    @media (max-width: 1060px) {
      padding: 32px 0 25px !important;
    }

    .loader {
      margin: 80px 0 80px;
      justify-content: center;
    }

    @media (max-width: 520px) {
      .form {
        width: 100%;

        &__group{
          > div{
            width: 100%;
          }
        }
      }
    }

    p {
      font-weight: 600;
    }
  }

  &__action {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    z-index: 10;

    @media (max-width: 1060px) {
      padding: 15px 0 24px;
      position: sticky;
      bottom: 0;
      margin-top: 0;
      z-index: 100;

      .button{
        width: 100%;
      }

      .button--transparent{
        display: none;
      }
    }
  }

  &--full_height{
    .modal{
      &__content{
        height: 95%;
      }

      &__wrapper{
        height: 100%;
      }
    }

  }

  &--wide {
    #{ $self }__wrapper {
      max-width: 1060px;
    }
  }

  &--narrow {
    #{ $self }__wrapper {
      max-width: 300px;

      @media (max-width: 520px) {
        max-width: unset;
      }
    }
  }

  &--little-narrow {
    #{ $self }__wrapper {
      max-width: 430px;

      @media (max-width: 520px) {
        max-width: unset;
      }
    }
  }

  &--standard {
    #{ $self }__wrapper {
      max-width: 618px;
    }
  }

  &--little-wide {
    #{ $self }__wrapper {
      max-width: 558px;
    }
  }

  &--large {
    #{ $self }__wrapper {
      max-width: 975px;
    }
  }

  &--extra-large {
    #{ $self }__wrapper {
      max-width: 1200px;
    }
  }

  &--warning {
    #{ $self }__action {

      button{
        &:last-child{
          background-color: $color-red;
        }
      }
    }
  }

  &--success {
    #{ $self }__action {

      button{
        &:last-child{
          background-color: #17C400;
        }
      }
    }
  }
}
