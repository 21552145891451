@import "styles/variables.scss";

.dashboard-layout__actions{
  margin-bottom: 40px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 40px;
  background-color: $color-grey-light;
  border-radius: 5px;

  @media (max-width: 1060px) {
    display: none;
  }

  span{
    font-weight: 600;
  }

  &__items{
    display: flex;
    gap: 32px;
    row-gap: 20px;
    align-items: center;
    flex-wrap: wrap;

    > div{
      position: relative;
    }
  }

  &__helper-button{
    padding: 0 11px;
    color: #E1E6EB;
    background-color: #7F22CE;
    height: 28px;
    width: 28px;
    border-radius: 30px;
    position: absolute;
    top: -8px;
    left: calc(100% - 15px);
    font-weight: 600;
    white-space: nowrap;
    font-size: 12px;
    transition: all ease 0.2s;
    overflow: hidden;
    display: flex;
    align-items: center;

    > div{
      position: absolute;
      transition: opacity ease 0.2s;
    }

    span{
      margin-left: 10px;
      font-size: 12px;
      color: #E1E6EB;
      position: absolute;
      left: 0;
      opacity: 0;
      transition: opacity ease 0.2s;
    }

    &:hover{
      width: 103px;
    
      > div{
        opacity: 0;
      }

      span{
        margin-left: 0;
        opacity: 1;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}