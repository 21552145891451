@import "styles/variables.scss";

.table {
  $self: &;
  display: flex;
  flex-direction: column;
  border: 1px solid $color-grey;
  border-radius: 5px;

  @media (max-width: 1060px) {
    border: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 24px 30px;
    padding-bottom: 40px;

    @media (max-width: 1060px) {
      display: none !important;
    }

    @media (max-width: 930px) {
      padding: 15px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 32px;
      flex: 1;

      &__filters {
        display: flex;
        align-items: center;
        gap: 56px;

        @media (max-width: 1300px) {
          gap: 20px;
        }

        @media (max-width: 1050px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .input {
        max-width: 288px;
        width: 100%;
      }

      .select{
        max-width: 288px;
        width: 100%;
      }

      .switch {
        margin-top: 33px;
      }

      @media (max-width: 930px) {
        gap: 10px;
      }

      .calendar {
        max-width: 280px;
      }
    }

    &__aside {
      display: flex;
      justify-content: flex-end;
    }

    &__mobile{
      margin-bottom: 24px;
      display: none;

      @media (max-width: 1060px) {
        display: flex;
      }

      .pill-grid span{
        display: flex;
      }

      &__wrapper{
        display: flex;
        align-items: center;
        gap: 16px;

        &__active{
          display: flex;
          align-items: center;
          gap: 10px;

          svg{
            path{
              fill: #848484
            }
          }
        }
      }

      &__modal{
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;

    &__wrapper {
      min-width: 100%;
      width: fit-content;

      @media (max-width: 1060px) {
        width: 100%;
      }
    }

    .table-loader {
      padding: 20px 0;
      display: flex;
      height: 200px;
    }

    .table-empty {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 200px;
      gap: 10px;

      span {
        text-align: center;
        font-weight: 600;
      }
    }
  }

  &__row {
    display: flex;
    height: 90px;
    border-bottom: 1px solid #e1e6eb;

    &:hover{
      background-color: #F7F9FA;
    }

    @media (max-width: 930px) {
      height: 65px;
    }

    &:last-child {
      border-bottom: none;
    }

    &--header {
      background-color: $color-grey;
      height: 50px;

      @media (max-width: 1060px) {
        display: none;
      }

      &:hover{
        background-color: $color-grey;
      }

      #{ $self }__col {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        span {
          display: flex;
          align-items: center;
          gap: 16px;

          svg {
            margin-left: 10px;
            width: 16px;
            height: 20px;
          }
        }

        &--asc,
        &--desc {
          #{ $self }__col__arrow {
            path {
              fill: #0f0fff;
            }
          }
        }

        &--desc {
          #{ $self }__col__arrow {
            transform: rotate(180deg);
          }
        }
      }
    }

    &--disabled {
      #{ $self }__col {
        span {
          color: $text-color-light;
        }

        button {
          background-color: transparent;
          color: $text-color-light;

          &:hover {
            background-color: rgba($text-color-light, 0.2);
            color: $text-color;
          }
        }
      }
    }
  }

  &__col {
    display: flex;
    align-items: center;
    padding: 0 30px;
    flex: 1;
    border-right: 2px solid #fff;
    overflow: hidden;

    @media (max-width: 930px) {
      padding: 0 15px;
    }

    &:last-child {
      border-right: none;
    }

    span, a {
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &--website {
      min-width: 70px;
      max-width: 320px;
      width: 100%;
    }

    &--website-full {
      min-width: 20px;
      width: 100%;
    }

    &--title {
      max-width: 405px;
      width: 100%;
    }

    &--email{
      max-width: 480px;
      width: 100%;
    }

    &--account_type{
      max-width: 180px;
      width: 100%;
    }

    &--phone{
      max-width: 255px;
      width: 100%;
    }

    &--title-full {
      min-width: 380px;
      max-width: 130px;
      width: 100%;
    }

    &--notification_status {
      max-width: 74px;
      width: 100%;
    }

    &--first_name {
      min-width: 250px;
      width: 100%;
    }

    &--active_plans {
      min-width: 100px;
      max-width: 290px;
      width: 100%;
    }

    &--wallet {
      min-width: 100px;
      max-width: 290px;
      width: 100%;
    }

    &--last_payout_date {
      min-width: 220px;
      width: 100%;
    }

    &--tasks{
      max-width: 250px;
      width: 100%;
    }

    &--urgent_count,
    &--high_count,
    &--normal_count {
      max-width: 290px;
      width: 100%;
    }

    &--status-pill {
      min-width: 220px;
      max-width: 220px;

      .pill{
        text-align: center;
        height: unset;
        padding: 4px 20px;
        line-height: 22px;
        min-width: 160px;
        white-space: pre-wrap !important;
      }
    }

    &--status {
      max-width: 280px;
      width: 100%;
    }

    &--mark_as_read {
      flex: 0;
      justify-content: flex-end;
      min-width: 230px;
    }

    &--date,
    &--created_at,
    &--date_added,
    &--updated_at,
    &--due_date {
      width: 100%;
      min-width: 170px;
      max-width: 170px;
    }

    &--late_payment {
      flex: 0.5;
      min-width: 205px;
    }

    &--subscription_plan {
      flex: 0;
      min-width: 240px;
    }

    &--amount {
      max-width: 160px;
      min-width: 160px;
      width: 100%;
    }

    &--your_comission {
      max-width: 160px;
      min-width: 230px;
      width: 100%;
    }

    &--referral_status {
      max-width: 160px;
      min-width: 170px;
      width: 100%;
    }

    &--show_invoice {
      flex: 0;
      justify-content: flex-end;
      min-width: 210px;
    }

    &--more_details {
      max-width: 200px;
      min-width: 200px;
      width: 100%;
    }

    &--priority {
      min-width: 145px;
      max-width: 145px;
      width: 100%;
    }

    &--estimated_time {
      max-width: 210px;
      min-width: 210px;
      width: 100%;
    }

    &--time_tracked {
      max-width: 195px;
      min-width: 195px;
      width: 100%;
    }

    &--template{
      max-width: 1050px;
      width: 100%;
    }

    &--time_over{
      max-width: 215px;
      width: 100%;
    }
  }

  &__status {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: $text-color-light;

    &--active {
      background-color: $primary-color;
    }
  }

  &__link {
    color: #2222cc;
    font-weight: 600;
  }

  &__clickable_text {
    color: #2222cc;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__website_status {
    display: flex;
    align-items: center;
    gap: 16px;

    &::before {
      content: "";
      height: 12px;
      width: 12px;
      background-color: #00d621;
      border-radius: 50%;
      flex-shrink: 0;
    }

    &--payment_problem, &--website_problem {
      &::before {
        background-color: #ff0000;
      }
    }

    &--verification {
      &::before {
        background-color: $primary-color;
      }
    }
  }

  &__website_items{
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    a{
      color: #0F0FFF;
      white-space: nowrap;
    }
  }
}
