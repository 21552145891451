@import "styles/variables.scss";

.tooltip {
  $self: &;
  display: flex;
  position: relative;

  // &:hover {
  //   svg {
  //     path {
  //       fill: #0f0fff;
  //       stroke: #0f0fff;

  //       &:last-child {
  //         fill: #fff;
  //         stroke: none;
  //       }
  //     }
  //   }
  // }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    background-color: #0f0fff;
    border-radius: 20px;
    border-top-right-radius: 3px;
    z-index: 30;
    opacity: 0;
    animation-name: fade;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    width: 200px;

    span {
      color: #fff !important;
      font-size: 13px !important;
      min-width: unset;
      text-align: center;
    }

    &::before {
      position: absolute;
      top: -6px;
      right: 4px;
      // transform: translateX(-50%);
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent #0f0fff transparent;
    }
  }

  &--center {
    #{ $self }__content {
      right: 50%;
      top: unset;
      bottom: calc(100% + 10px);
      transform: translateX(50%);
      border-radius: 20px;
      background-color: #2222cc;

      &::before {
        right: 50%;
        top: unset;
        bottom: -6px;
        transform: rotate(-180deg) translateX(-50%);
        border-color: transparent transparent #2222cc transparent;
      }
    }
  }

  &--info {
    #{ $self }__content {
      right: 50%;
      bottom: unset;
      top: calc(100% + 10px);
      transform: translateX(50%);
      border-radius: 20px;
      background-color: #ebeef0;
      height: 27px;
      width: fit-content;
      white-space: nowrap;

      span{
        font-weight: 600;
        color: #000000 !important;
        font-size: 12px !important;
      }

      &::before {
        right: 50%;
        bottom: unset;
        top: -6px;
        transform: translateX(50%);
        border-color: transparent transparent #ebeef0 transparent;
      }
    }
  }

  &--medium{
    #{ $self }__content {
    width: 250px;
    }
  }


  &--warning {
    #{ $self }__content {
      span {
        color: #ea3a3d !important;
      }
    }
  }


  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
