@import "styles/variables.scss";

.switch {
  display: flex;
  position: relative;
  width: fit-content;

  label {
    font-size: 14px;
    color: $text-color;
    font-weight: 600;
    line-height: 20px;
    display: block;
    position: relative;
    padding-left: 60px;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;

    @media (max-width: 620px) {
      font-size: 12px;
    }
  }

  input {
    display: none;

    &:checked {
      ~ .checkmark {
        background-color: #d0d0fd;
        &::after {
          left: calc(100% - 10px);
          background-color: $primary-color;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0px;
    left: 0;
    height: 22px;
    width: 45px;
    border-radius: 20px;
    background-color: #e1e6eb;
    transition: border-color ease 0.2s, background-color ease 0.2s;

    &::after {
      content: "";
      position: absolute;
      left: calc(0% + 10px);
      top: 50%;
      transform: translate(-50%, -50%);
      transition: left ease 0.2s, background-color ease 0.2s;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background-color: $text-color-light;
    }
  }

  &--error {
    .checkmark {
      border-color: #d65252;
    }
  }

  &--no-label {
    label {
      height: 18px;
      padding-left: 45px;
    }
  }
}
