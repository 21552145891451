.dashboard-plan {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 16px;
    background-color: #f7f9fa;

    span {
      width: 100%;

      > span {
        color: #ff0000;
      }

      &:first-child {
        // max-width: 150px;
        font-weight: 600;
      }

      &:last-child {
        max-width: 160px;
      }
    }
  }

  &__warning{
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 25px;

    &__info{
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      h3{
        text-align: center;
      }

      span{
        margin: 16px 0 32px;
        text-align: center;
      }
    }

    &__list{
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #F7F9FA;
      margin-left: -24px;
      margin-right: -24px;
      margin-bottom: -24px;
      padding: 15px 0;

      &__wrapper{
        display: flex;
        flex-direction: column;
      }

      &__item{
        display: flex;
        align-items: center;

        span{
          text-align: left;

          &:first-child{
            width: 150px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
