.status-ok{
  position: relative;
  display: flex;

  svg{
    &:last-of-type{
      position: absolute;
      top: calc(50% - 40px);
      left: calc(50% - 70px);
      animation: dots-rotate 6s linear infinite;
    }
  }
}

@keyframes dots-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}