@import "styles/variables.scss";

.dashboard-layout-sidebar__menu {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  flex: 1;

  @media (max-width: 930px) {
    margin-top: 30px;
  }

  @media (max-width: 1060px) {
    margin-top: 0;
    gap: 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: 1060px) {
      gap: 5px;
    }

    &__count{
      padding: 2px 4px;
      background-color: #9734eb;
      height: 16px;
      min-width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      color: #fff;
      font-size: 11px;
      font-weight: 600;
    }

    > a,
    button {
      padding: 0px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 30px;
      height: 50px;

      @media (max-width: 1060px) {
        padding: 0;
      }

      span {
        font-weight: 600;
        display: flex;
        align-items: center;
        line-height: 20px;

        svg {
          margin-right: 15px;
          width: 18px;
          height: 20px;

          @media (max-width: 1060px) {
            path{
              fill: #0F0FFF;
            }
          }
        }
      }

      @media (min-width: 1060px) {
        &:hover {
          background-color: #EDEDED;
          text-decoration: none;
        }
      }
    }

    &__extend {
      width: 100%;

      button {
        width: 100%;

        > svg {
          transition: transform ease 0.3s;
        }
      }

      &__items {
        padding-left: 30px;
        transition: height 0.5s;
        height: 0px;
        overflow: hidden;

        > div {
          margin-top: 5px;
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        a {
          padding: 0 15px;
          display: flex;
          align-items: center;
          height: 40px;
          border-radius: 25px;

          span {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.6;
          }

          &:hover {
            background-color: #0f0fff;

            text-decoration: none;

            span {
              color: #fff;
            }
          }

          &.wrong {
            &::before {
              content: "";
              margin-right: 8px;
              height: 10px;
              width: 10px;
              background-color: $color-red;
              border-radius: 50%;
              flex-shrink: 0;
            }
          }

          &.verification {
            &::before {
              content: "";
              margin-right: 8px;
              height: 10px;
              width: 10px;
              background-color: $primary-color;
              border-radius: 50%;
              flex-shrink: 0;
            }
          }

          &.disabled{
            opacity: 0.5;

            &::before {
              background-color: #DECD00;
            }
          }

          &::before {
            content: "";
            margin-right: 8px;
            height: 10px;
            width: 10px;
            background-color: $color-green;
            border-radius: 50%;
            flex-shrink: 0;
          }
        }
      }

      &--open {
        button {
          svg {
            transform: rotate(-180deg);
          }
        }
      }
    }

    &--active {
      @media (min-width: 1060px) {
        background-color: #0f0fff;
        text-decoration: none;
  
        &:hover {
          background-color: #0f0fff !important;
        }

        span {
          color: #fff;
  
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}
