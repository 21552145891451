.dashboard-layout {
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;

    @media (max-width: 1300px) {
      border-radius: 0;
    }

    @media (max-width: 1060px) {
      padding-bottom: 170px;
    }

    &__wrapper {
      padding: 24px 40px;
      width: calc(100vw - 295px);
      flex: 1;
      position: relative;

      @media (max-width: 1060px) {
        padding-top: 0 !important;
      }

      &.no-padding{
        padding-bottom: 0 !important;
      }

      &.less-padding{
        padding-left: 24px;
        padding-right: 24px;

        @media (max-width: 1060px) {
          padding: 0 16px;
        }
      }

      @media (max-width: 1300px) {
        width: 100%;
      }

      @media (max-width: 1060px) {
        padding: 15px;
      }

      &__breadcrumps{
        margin-bottom: 16px;

        @media (max-width: 1060px) {
          margin-bottom: 0;
        }
      }
    }

    &__mobile-header{
      margin-top: 32px;
      padding: 0 16px;
      padding-bottom: 30px;
      display: none;

      @media (max-width: 1060px) {
        display: block;
      }
    }
  }
}
