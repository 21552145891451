.task-onboarding{
  &__backdrop{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#333333, 0.6);
    z-index: 1100;
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  &__tooltip{
    position: absolute;
    z-index: 1102;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;


    &__pointer{
      height: 24px;
      width: 24px;
      background-color: #9734EB;
      border-radius: 50%;
      position: relative;
      animation: pulse-purple 2s infinite;

      &::before{
        content: "";
        height: 28px;
        width: 28px;
        border: 1px solid #9734EB;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    }

    &__content{
      padding: 16px;
      max-width: 440px;
      background-color: #9734EB;
      border-radius: 5px;

      p, a{
        color: #fff;
        font-weight: 600;
        font-size: 16px;
      }

      a{
        text-decoration: underline;
      }

      > span{
        margin-top: 30px;
        color: #fff;
        font-size: 14px;
        display: block;
      }

      &__actions{
        margin-top: 24px;
        display: flex;
        justify-content: space-between;

        button, a{
          padding: 0px 12px;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 14px;
          height: 34px;
          background-color: #8E3AD6;
          border-radius: 200px;
          transition: all ease 0.3s;
          color: #fff;
          text-decoration: unset;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover{
            background-color: #7F22CE;
          }
        }
      }

      &__pagination{
        margin: 4px 6px 0 0;
        display: flex;
        justify-content: space-between;

        button{
          margin: 0;
          padding: 0;
          font-weight: 600;
          font-size: 12px;

          &:hover{
            text-decoration: underline;
          }
        }

        span{
          color: #fff;
          font-size: 12px;
          font-weight: 600;
        }

        &--hidden{
          justify-content: flex-end;


          button{
            display: none;
          }
        }
      }
    }
  }
}

@keyframes pulse-purple {
  0% {
    transform: scale(0.85);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.85);
  }
}

@keyframes scale-up-center {
  0% {
            opacity: 0;
  }
  100% {
            opacity: 1;
  }
}