@import "styles/variables.scss";

.formik-mobile-radio{
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__item{
    padding: 12px 24px;
    border: 1px solid #E1E6EB;
    background-color: #FFFFFF;
    border-radius: 5px;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &::after {
      content: "";
      height: 24px;
      width: 24px;
      // position: absolute;
      // top: 15px;
      // right: 15px;
      background-color: #f7f9fa;
      border: 1px solid #ebeef0;
      border-radius: 50%;
    }

    &--selected{
      background-color: #0F0FFF;

      &::after {
        content: "";
        background-image: url("/assets/icons/circle-checkmark.svg");
      }

      .formik-mobile-radio{
        &__item{

          &__text{
            span{
              color: #FFFFFF !important;
            }
          }
        }
      }
    }

    &__text{
      display: flex;
      flex-direction: column;

      span{
        &:first-child{
          font-weight: 600;
        }

        &:nth-child(3){
          margin-top: 8px;
          font-weight: 600;
          color: #0F0FFF;
        }
      }
    }

    &__radio{
      height: 24px;
      width: 24px;
      background-color: #F7F9FA;
      border: 1px solid #EBEEF0;
      border-radius: 50%;
    }
  }
}
