@import "styles/variables.scss";

.details-card {
  $self: &;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__item {
      display: flex;
      align-items: flex-start;
      gap: 50px;

      @media (max-width: 1060px) {
        flex-direction: column;
        gap: 5px;
      }

      > label {
        font-weight: 600;
        word-break: break-word;
        max-width: 200px;

        @media (max-width: 1060px) {
          max-width: unset !important;
          width: 100% !important;
        }
      }

      > span {
        display: flex;
        gap: 5px;
        flex: 1;
        word-break: break-word;

        @media (max-width: 1060px) {
          width: 100%;

          .button{
            width: 100%;
          }
        }

        &.flex-end {
          justify-content: flex-end;
        }

        .tooltip {
          margin-top: -3px;
        }
      }

      > span {
        a{
          &:not(.button){

            color: #2222cc;
          }
        }
      }

      &.extra-padding {
        margin-bottom: 8px;
      }

      &.no-value {
        label {
          max-width: unset;
        }
      }

      &.full-width {
        label {
          max-width: unset;
        }
      }

      .button {
        border: none;
      }

      .tooltip {
        &:hover {
          svg {
            path {
              &:first-of-type {
                fill: #0f0fff;
                stroke: #0f0fff;
              }

              &:last-of-type {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }
}
