.dashboard-content {
  display: flex;
  flex-direction: column;

  .pill-grid {
    flex-direction: row;
    align-items: center;
    gap: 24px;

    span {
      font-size: 22px;
      font-weight: 800;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    .details-card{
      width: 31.9% !important;
      min-width: 350px;

      &:last-child{
        width: 66% !important;
        max-width: 1170px;

        @media (max-width: 1060px) {
          width: 100% !important;
        }
      }

      @media (max-width: 1060px) {
        width: 100% !important;
      }
    }

    @media (max-width: 1060px) {
      gap: 15px;
      margin-top: 0 !important;
      flex-direction: column;
    }
  }
}
