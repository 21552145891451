@import "styles/variables.scss";

.webmaster-select{
  &__list{
    margin-top: 5px;
    display: flex;
    flex-direction: column;

    &__item{
      padding: 12px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color-grey;

      &:last-child{
        border-bottom: none;
      }

      &__name{
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $primary-color;
        gap: 10px;

        img{
          height: 24px;
          width: 24px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
  }
}
