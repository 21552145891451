.task-conversation__details__priority-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  &__button {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter ease 0.2s;

    &:hover {
      filter: brightness(1.06);
    }

    svg {
      height: 18px;
      width: 18px;

      path {
        fill: #14e0e0;
      }
    }

    &--high {
      svg {
        path {
          fill: #decd00;
        }
      }
    }

    &--urgent {
      svg {
        path {
          fill: #de1100;
        }
      }
    }
  }
}
