.task-conversation__message {
  $self: &;
  display: flex;
  flex-direction: column;

  &:hover{
    #{ $self }__header__meta__actions {
      display: flex;
    }

    #{ $self }__header__meta {

      &--date{
        display: none;
      }
    }
  }

  &__header {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f9fa;
    border: 1px solid #e1e6eb;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @media (max-width: 1080px) {
      padding: 10px 15px;
    }

    > span {
      font-size: 14px;

      @media (max-width: 1080px) {
        font-size: 12px;
      }
    }

    &__user {
      display: flex;
      align-items: center;
      gap: 24px;

      @media (max-width: 1080px) {
        gap: 10px;
      }

      > span {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
      }

      &__avatar {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;

      }
    }

    &__meta{
      display: flex;
      align-items: center;
      text-align: right;
      gap: 15px;

      @media (max-width: 1060px) {
        display: none;
      }

      &__actions{
        display: none;

        button{
          display: flex;
          align-items: center;
          color: #333333;
          font-weight: 600;
          gap: 7px;
          font-size: 13px;

          svg{
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }

  &__content {
    padding: 16px 24px;
    background-color: #fff;
    border: 1px solid #e1e6eb;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    word-break: break-word;

    @media (max-width: 1060px) {
      padding: 16px;
    }

    span{
      // display: block;
      margin-bottom: 10px;
      font-size: 14px;
    }

    p {
      font-weight: 600;
      white-space: pre-wrap;
    }

    a{
      color: #2222cc;
      text-decoration: underline;
    }

    > div{
      white-space: pre-wrap;
      line-height: 25px;
    }

    ul{
      margin-left: 15px;
      line-height: 20px;

      li{
        margin: 0;
        padding: 0;
      }
    }
  }
}
