@import "styles/variables.scss";

.file-uploader {
  > span {
    display: block;
    margin-bottom: 12px;
    color: $text-color;
    font-size: 14px;
    font-weight: 600;

    @media (max-width: 1060px) {
      margin-bottom: 8px;
    }
  }

  &__input {
    padding: 44px 15px;
    border-radius: 10px;
    width: 100%;
    background-color: $color-grey-light;
    border: 1px solid $color-grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 1060px) {
      padding: 15px 45px;
    }

    > span {
      margin: 20px 0 8px;
      text-align: center;

      @media (max-width: 1060px) {
        font-size: 14px;
        max-width: 175px;
        line-height: 22px;
      }

      span {
        color: $primary-color;

        @media (max-width: 1060px) {
          font-size: 14px;
        }
      }
    }

    small {
      font-size: 12px;
      color: #848484;
      text-align: center;

      @media (max-width: 1060px) {
        line-height: 20px;
      }
    }
  }

  &__error {
    color: #ff0000 !important;
  }

  &__progress {
    margin-top: 16px;
    height: 6px;
    width: 100%;
    border-radius: 6px;
    background-color: #ebeef0;
    overflow: hidden;

    @media (max-width: 1060px) {
      margin-top: 24px !important;
    }

    > div {
      height: 100%;
      background-color: #0f0fff;
      border-radius: 5px;
      transition: all ease 0.2s;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;

    &__item {
      padding: 16px 0;
      display: flex;
      align-content: center;
      justify-content: space-between;
      border-bottom: 1px solid #ebeef0;

      span {
        font-size: 12px;
        font-weight: 600;
      }

      button {
        color: #848484;
        font-size: 12px;
        font-weight: 600;

        &:hover {
          color: #ff0000;
        }
      }
    }
  }

  > small {
    display: block;
    margin-top: 10px;
    font-size: 12px;
    color: #848484;
  }
}
