.contact-form {
  &__form {
    width: 100%;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1060px) {
      justify-content: center;

      .button{
        width: 100%;
      }
    }
  }
}
