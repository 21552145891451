@import "styles/variables.scss";

.details-card-language{
  display: flex;
  gap: 10px;
  align-items: center;

  &__icon{
    height: 41px;
    width: 41px;
  }
}
