@import "styles/variables.scss";

.details-card-avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;

  @media (max-width: 1300px) {
    height: 45px;
    width: 45px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
