@import "styles/variables.scss";

.mobile-modal-options{
    margin: 24px 0 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__item{
        padding: 24px;
        border: 1px solid #E1E6EB;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        color: #333333;
        gap: 15px;

        svg{
            height: 18px;
            width: 18px;
        }

        &__text{
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin-right: auto;

            span{
                font-size: 22px;
                line-height: 32px;
                font-weight: 700;
            }

            small{
                font-size: 12px;
                line-height: 20px;
                font-weight: 400;
            }
        }

        &--with-subtitle{
            .mobile-modal-options{
                &__item{
                    &__text{
                        span{
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        &--radio{
            &::before {
                content: "";
                height: 24px;
                width: 24px;
                background-color: #f7f9fa;
                border: 1px solid #ebeef0;
                border-radius: 50%;
              }

              &--checked{
                &::before{
                    content: "";
                    background-image: url("/assets/icons/circle-checkmark.svg");
                }
              }
        }

        &--error{
            box-shadow: 0 0 0 4px rgba($color-red, 0.1);
            border-color: rgba($color-red, 0.8);
        }
    }
}