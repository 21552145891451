.new-project-content__choose{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    
    &__items{
        display: flex;
        gap: 32px;

        @media (max-width: 1060px) {
            flex-direction: column;
        }
        
    }

    .details-card{
        z-index: 1001;

        &__content{
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: auto;
        }
    }

    &__header{
        padding: 60px 0 40px;
    }
    
    &__description{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 100px;

        @media (max-width: 1060px) {
            max-height: 100%;
        }

        h3{
            margin: 32px 0;
            text-align: center;
        }

        p{
            text-align: center;
        }

        .button{
            margin-top: 40px;
            flex-shrink: 0;
        }
    }

    &__buttons{
        display: flex;
        flex-direction: column;
        max-width: 304px;
        width: 100%;
        gap: 16px;

        .radio-button{
            width: 100%;
        }
    }

    &__backdrop{
        position: fixed;
        top: 134px;
        right: 0;
        bottom: 0;
        left: 280px;
        z-index: 11;
        background-color: rgba(#333333, 0.4);
        transition: all ease 0.3s;
        z-index: 1000;

        @media (max-width: 1300px) {
            top: 88px;
            left: 0;
        }
    }

    &__bottom {
        margin-top: 22px;
        padding: 10px 0;
        display: flex;
        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 900;
      }
}