@import "styles/variables.scss";

.select {
  font-size: 14px;
  outline-width: 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: border-color ease 0.2s, fill ease 0.2s, filter ease 0.2s;
  z-index: 12;
  

  label {
    display: block;
    margin-bottom: 12px;
    color: $text-color;
    font-size: 14px;
    font-weight: 600;

    @media (max-width: 1060px) {
      margin-bottom: 8px;
      font-size: 16px;
    }

    span {
      color: $primary-color;
    }
  }

  > button {
    padding: 0 15px;
    border-radius: 10px;
    height: 44px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-grey-light;
    border: 1px solid $color-grey;

    @media (max-width: 1060px) {
      height: 46px;
    }

    span {
      display: flex;
      align-items: center;
      gap: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 500;

      @media (max-width: 1060px) {
        font-size: 16px;
      }

      > img {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    background-color: $color-grey-light;
    border: 1px solid $color-grey;
    border-radius: 10px;
    width: 100%;
    max-height: 242px;
    overflow: hidden;
    box-shadow: 0px 4px 6px -3px rgba(51, 51, 51, 0.3);
    z-index: -100;

    &__searcher {
      input {
        padding: 7px 13px;
        border: none;
        background-color: $color-grey-light;
        border-bottom: 1px solid #e4e7ea;
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        width: 100%;

        &::placeholder {
          font-size: 14px;
        }
      }
    }

    &__wrapper {
      overflow: auto;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start !important;
      text-align: left;
      padding: 13px;
      color: $text-color;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      width: 100%;
      gap: 15px;

      @media (max-width: 1060px) {
        font-size: 16px;
      }

      img {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        object-fit: cover;
      }

      &:hover {
        background-color: $color-grey;
      }

      &--selected {
        background-color: $color-grey;
      }
    }
  }

  &__error {
    display: block;
    margin-top: 5px;
    color: $color-red;
    font-size: 13px;
  }

  &--active {
    z-index: 1000;

    > button {
      box-shadow: 0 0 0 4px rgba($primary-color, 0.1);
      border-color: rgba($primary-color, 0.8);

      > svg {
        transform: rotate(-180deg);
      }
    }
  }

  &--error {
    > button {
      box-shadow: 0 0 0 4px rgba($color-red, 0.1);
      border-color: rgba($color-red, 0.8);
    }
  }

  &--selected {
    > span {
      color: #ececf1;
    }
  }

  &--empty{
    > button {

span{

  color: #848484;
}
    }
  }

  &--top {
    .select {
      &__options {
        top: unset;
        bottom: calc(100% - 15px);
      }
    }
  }

  &--disabled {
    pointer-events: none;
  }
}
