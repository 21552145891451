.website-info-modal {
  $self: &;
  display: flex;
  gap: 32px;

  @media (max-width: 980px) {
    flex-direction: column;

    #{ $self }__col {
      max-width: unset;

      &:last-child {
        max-width: unset;
      }
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 560px;
    flex: 1;

    &:last-child {
      max-width: 600px;
    }
  }
}
