.details-card__payment-error {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  > span {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    max-width: 90%;
  }
}
