@import "styles/variables.scss";

.profile-photo-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 256px;
  height: 256px;
  background-color: $color-grey-light;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  //   border: none !important;

  @media (max-width: 1060px) {
    margin: 0 auto;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 20px;

    span {
      margin: 15px 0 8px;
      text-align: center;
      font-size: 14px;

      > span {
        color: $primary-color;
      }
    }

    small {
      text-align: center;
      color: $text-color-light;
    }
  }

  &--loading {
    pointer-events: none;
  }

  &__loader {
    background-color: rgba($text-color, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    span {
      color: #fff;
      font-weight: 800;
      font-size: 13px;
    }
  }

  &__error {
    display: block;
    margin-top: 15px;
    color: $color-red;
  }
}
