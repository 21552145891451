@import "styles/variables.scss";

.calendar {
  position: relative;
  width: fit-content;
  width: 100%;

  @media (max-width: 560px) {
    position: unset;
  }

  label {
    display: block;
    margin-bottom: 12px;
    color: $text-color;
    font-size: 14px;
    font-weight: 600;

    @media (max-width: 1060px) {
      margin-bottom: 8px;
      font-size: 16px;
    }

    span {
      color: $primary-color;
    }
  }

  &__button {
    padding: 0 15px;
    height: 44px;
    // width: 280px;
    width: 100%;
    background-color: $color-grey-light;
    border: 1px solid $color-grey;
    border-radius: 10px;
    color: $text-color-light;
    display: flex;
    align-items: center;
    position: relative;

    &__aside-button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-48%);
      height: 15px;
      width: 15px;

      &:hover {
        filter: brightness(0.9);
      }
    }

    &--active {
      color: $text-color;
    }

    &--focus {
      box-shadow: 0 0 0 4px rgba($primary-color, 0.1);
      border-color: rgba($primary-color, 0.8);
    }

    &--error {
      box-shadow: 0 0 0 4px rgba($color-red, 0.1);
      border-color: rgba($color-red, 0.8);
    }
  }

  &__content {
    position: absolute;
    top: calc(100% + 20px);
    z-index: 50;

    &--months {
      width: 320px;
    }

    &--days {
      width: 470px;
    }

    &--top {
      top: unset;
      bottom: calc(100% - 15px);

      .dropdown-card--left {
        &::before {
          bottom: -10px;
          top: unset;
          transform: rotate(-45deg);
        }
      }
    }

    @media (max-width: 560px) {
      top: 118px;
      left: 16px !important;
      right: 16px !important;
      width: auto;
    }
  }

  .dropdown-card {
    &__wrapper {
      padding: 20px;
    }
  }
}
