.message-editor {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  width: 100%;
  flex-shrink: 0;
  background-color: #f7f9fa;
  border: 1px solid #e1e6eb;
  border-radius: 5px;
  // overflow: hidden;

  .DraftEditor-root{
    height: fit-content;
  }

  .rdw-link-decorator-wrapper{
    display: inline;
    text-decoration: underline;

    a{
      span{
        color: #2222cc !important;
      }
    }
  }

  .rdw-editor-toolbar{
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  
  .rdw-emoji-modal{
    top: unset !important;
    bottom: 35px !important;
  }

  .rdw-link-modal{
    top: unset !important;
    bottom: 35px !important;
    height: fit-content;
    box-shadow: none;
    background-color: #fff;
    border: 1px solid #e1e6eb;
    border-radius: 5px;

    .rdw-link-modal-label{
      color: #333333;
      font-size: 13px;
      font-weight: 600;

      &:first-child{
        display: none;
      }
    }

    .rdw-link-modal-input{
      margin-top: 0;
      padding: 0 15px;
      height: 34px;
      width: 100%;
      background-color: #f7f9fa;
      border: 1px solid #ebeef0;
      border-radius: 8px;
    }

    // padding: 0 15px;
    // height: 44px;
    // width: 100%;
    // background-color: #f7f9fa;
    // border: 1px solid #ebeef0;

    .rdw-link-modal-target-option{
      display: none;
    }

    #linkTitle{
      display: none;
    }

    .rdw-link-modal-buttonsection{
      button{
        font-family: "area-normal", sans-serif !important;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        border-radius: 15px;
        border: none;


        &:first-child{
          background-color: #2222cc;
        }

        &:last-child{
          background-color: transparent;
          border: 1px solid #2222cc;
          color: #2222cc;
        }

        &:hover{
          filter: brightness(1.2);
        }
      }
    }
  }

  &__editor{
    padding: 0 10px;
    font-size: 13px;
    height: fit-content;

    span{
      font-size: 16px;
    }
  }

  textarea {
    padding: 24px;
    padding-bottom: 10px;
    width: 100%;
    flex: 1;
    resize: none;
    background-color: transparent;
    border: none;
    font-weight: 600;
  }

  &__action {
    padding: 24px;
    padding-top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 50px;

    &__attachments {
      flex: 1;

      &__progress {
        margin-top: 16px;
        height: 6px;
        width: 100%;
        border-radius: 6px;
        background-color: #ebeef0;
        overflow: hidden;

        > div {
          height: 100%;
          background-color: #0f0fff;
          border-radius: 5px;
          transition: all ease 0.2s;
        }
      }

      &__list {
        display: flex;
        flex-direction: column;

        &__item {
          padding: 12px 0;
          display: flex;
          align-content: center;
          justify-content: space-between;
          border-bottom: 1px solid #ebeef0;
          flex: 1;
          gap: 5px;

          &:last-child {
            border-bottom: none;
          }


          > div{
            flex: 1;
            display: flex;
            overflow: hidden;
          }

          span {
            font-size: 12px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            line-height: 1.6;
            flex: 1;
            width: 50px;
            white-space: nowrap;
            text-overflow: ellipsis;


          }

          button {
            color: #848484;
            font-size: 12px;
            font-weight: 600;
            white-space: nowrap;

            &:hover {
              color: #ff0000;
            }
          }
        }
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 40px;

      label {
        cursor: pointer;
      }

      input {
        display: none;
      }

      &__group{
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
