.dashboard-content__tasks-overview {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    padding: 16px 24px;
    display: flex;
    background-color: #f7f9fa;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__label {
      display: flex;
      align-items: center;
      gap: 16px;

      span {
        font-weight: 600;
      }
    }
  }
}
