.placeholder-page {
  padding: 70px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100% - 130px);

  @media (max-width: 1060px) {
    height: 100%;
    padding: 30px 0 0;
  }

  &__wrapper {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      text-align: center;
    }

    p {
      margin: 40px 0 64px;
      max-width: 840px;
      width: 100%;
      text-align: center;

      @media (max-width: 1060px) {
        margin: 24px 0 40px;
      }
    }

    &__buttons{
      display: flex;
      gap: 20px;

      @media (max-width: 930px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &--auth {
    padding: 120px 0 80px;
  }
}
