@import "styles/variables.scss";

.icon-button {
  position: relative;
  display: flex;

  &__content {
    display: flex;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    transform: translate(-50%, -50%);
    background-color: rgba($primary-color, 0.1);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    opacity: 0;
    transition: all ease 0.2s;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  svg {
    height: 23px;
    width: 20px;
  }

  &__badge {
    padding: 2px;
    position: absolute;
    top: -6px;
    right: -7px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    min-width: 16px;
    background-color: $color-purple;

    span {
      display: block;
      font-weight: 600;
      font-size: 11px;
      color: #fff;
    }
  }

  &--small {
    svg {
      height: 16px;
      width: 16px;
    }

    &::before {
      height: 36px;
      width: 36px;
    }
  }

  &--large {
    svg {
      height: 26px;
      width: 26px;
    }

    &::before {
      height: 46px;
      width: 46px;
    }
  }

  &--gray {
    svg {
      height: 26px;
      width: 26px;

      path{
        fill: #E1E6EB
      }
    }

    &:hover{
      svg{
        path{
          fill: $primary-color
        }
      }
    }
  }

  &--error {
    &::before {
      background-color: rgba(#E30D0D, 0.1);
    }

    svg {
      // height: 26px;
      // width: 26px;

      path{
        fill: #E30D0D
      }
    }

    &:hover{
      svg{
        path{
          fill: #E30D0D
        }
      }
    }
  }
}
