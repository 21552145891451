.cancel-plan-modal{
    padding: 0 0px;

    h2{
        font-weight: 600;
    }

    ul{
        margin-top: 24px;
        margin-left: 20px;

        li{
            margin-bottom: 16px;

            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
}