@import "styles/variables.scss";

.input {
  $self: &;

  label {
    display: block;
    margin-bottom: 12px;
    color: $text-color;
    font-size: 14px;
    font-weight: 600;

    @media (max-width: 1060px) {
      margin-bottom: 8px;
      font-size: 16px;
    }

    span {
      color: $primary-color;
    }
  }

  &__content {
    height: 44px;
    position: relative;

    @media (max-width: 1060px) {
      height: 46px;
    }

    input {
      padding: 0 15px;
      border-radius: 10px;
      height: 100%;
      width: 100%;
      background-color: $color-grey-light;
      border: 1px solid $color-grey;

      &:focus {
        box-shadow: 0 0 0 4px rgba($primary-color, 0.1);
        border-color: rgba($primary-color, 0.8);
      }
    }

    &__show-password{
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);

      svg{
        fill: #848484
      }
    }
  }

  &--active {
    #{ $self }__content {
      border-color: red;
    }
  }

  &--error {
    #{ $self } {
      &__content {
        input {
          box-shadow: 0 0 0 4px rgba($color-red, 0.1);
          border-color: rgba($color-red, 0.8);
        }
      }

      &__error {
        display: block;
        margin-top: 5px;
        color: $color-red;
        font-size: 13px;
      }
    }
  }

  &--uppercase-label {
    label {
      text-transform: uppercase;
      letter-spacing: 2.3px;
      font-size: 11px;
    }
  }
}
