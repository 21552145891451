@import "styles/variables.scss";

.new-website-content__choose-plan {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &__wrapper {
    display: flex;
    gap: 20px;

    @media (max-width: 1660px) {
      flex-direction: column;
    }

    // @media (max-width: 1300px) {
    //   flex-direction: row;
    // }

    // @media (max-width: 1090px) {
    //   flex-direction: column;
    // }
  }

  &__form {
    max-width: 445px;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    h3 {
      margin-bottom: 35px;
    }

    &__required-info {
      margin-top: 24px !important;

      small {
        color: #848484;
      }

      span {
        color: #0f0fff;
      }
    }

    &__bottom {
      &__terms {
        padding-top: 15px;
        border-top: 1px solid #e1e6eb;

        a {
          color: $primary-color;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }

  &__bottom {
    padding: 10px 0;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1001;

    &--mobile{
      display: flex;
      gap: 16px;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 96px;
      background-color: #fff;
      padding: 10px 16px;
      z-index: 1000;

      .button{
        flex: 1;
      }
    }

    @media (max-width: 1060px) {
      display: none;
      // position: fixed;
      // bottom: 96px;
      // padding: 10px 16px;

      // .button{
      //   flex: 1;
      // }
    }
  }
}
