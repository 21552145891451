@import "styles/variables.scss";

.all-entries-modal{
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    &__item{
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E1E6EB;

        a{
            color: $primary-color;
        }

        &--over{
            color: red;
        }
    }
}