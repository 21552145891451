.referral-programs__details {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  flex: 1;
  max-width: 1366px;

  &__wrapper {
    display: flex;
    gap: 32px;

    @media (max-width: 1240px) {
      flex-direction: column;
    }
  }

  &__col {
    display: flex;
    gap: 32px;
    flex-direction: column;

    &:first-child {
      min-width: 500px;
      max-width: 568px;
      width: 100%;

      @media (max-width: 1240px) {
        max-width: unset;
        min-width: unset;
      }
    }

    &:last-child {
      flex: 1;

      .details-card {
        height: fit-content;
      }
    }
  }

  &__wallet {
    padding: 24px 15px;
    background-color: #f7f9fa;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      text-align: center;
    }

    h1 {
      margin: 30px 0 0;
      font-size: 58px;
      line-height: 72px;
      text-align: center;
    }
  }

  &__referral-link {
    &__code {
      margin-bottom: 24px;
      display: flex;
      height: 54px;
      width: 100%;

      input {
        padding: 0 24px;
        height: 100%;
        flex: 1;
        background-color: #f7f9fa;
        border: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-size: 16px;
        line-height: 28px;
        font-weight: 600;
        color: #333333;
      }

      .button {
        padding: 0;
        height: 100%;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: unset;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;

        svg {
          margin-left: 0;
          height: 22px;
          width: 22px;
        }
      }
    }
  }

  &__referral-dashboard{
    padding: 24px;
    display: flex;
    border: 1px solid #e1e6eb;
    border-radius: 5px;

    @media (max-width: 1180px) {
      flex-direction: column;
      gap: 30px;
    }


    &__items{
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      gap: 10px;
      flex: 1;

      @media (max-width: 660px) {
        padding: 5px 0 30px;
        overflow-x: scroll;

        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 5px;
          background-color: #ebeef0;
        }
    
        &::-webkit-scrollbar-track:hover {
          background-color: #ebeef0;
        }
    
        &::-webkit-scrollbar-track:active {
          background-color: #ebeef0;
        }
    
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background-color: #2222cc;
        }
    
        &::-webkit-scrollbar-thumb:hover {
          background-color: #0f0fff;
        }
    
        &::-webkit-scrollbar-thumb:active {
          background-color: #0f0fff;
        }

      }

      &__item{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 166px;
        max-width: 166px;

        span{
          margin-top: 16px;
          text-align: center;
        }
      }
    }

    &__button{
      margin-left: 64px;
      max-width: 312px;
      width: 100%;
      background-color: #F7F9FA;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;

      @media (max-width: 1180px) {
        margin-left: 0;
        max-width: unset;
        padding: 30px 0;
      }
    }
  }
}
