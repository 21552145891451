@import "styles/variables.scss";

.mobile-bar{
  height: 96px;
  width: 100%;
  background-color: #F7F9FA;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  display: none;
  align-items: center;
  gap: 22px;
  padding: 0 16px;
  z-index: 1030;

  @media (max-width: 1060px) {
    display: flex;
  }

  &__items{
    display: flex;
    flex: 1;
    background-color: #0F0FFF;
    height: 64px;
    border-radius: 200px;
    justify-content: space-between;
    padding: 0 34px;

    a{
      padding: 0 0px;
      display: flex;
      align-items: center;
      position: relative;

      svg{
        height: 23px;

        path{
          fill: #fff
        }

        rect{
          fill: #fff;
        }
      }

      span{
        padding: 2px 4px;
        position: absolute;
        top: 13px;
        left: 13px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        min-width: 20px;
        background-color: $color-purple;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
    
        // span {
        //   display: block;
        //   font-weight: 600;
        //   font-size: 11px;
        //   color: #fff;
        // }
      }
    }
  }

  > a{
    height: 64px;
    width: 64px;
    background-color: #0F0FFF;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
      height: 24px;
      width: 24px;

      path{
        fill: #fff
      }
    }

  }
}