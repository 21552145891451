@import "styles/variables.scss";

.notification-dropdown {
  $self: &;
  width: 450px;

  &--empty {
    #{ $self }__content {
      overflow: unset;
    }
  }

  &__header {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-weight: 600;
    }

    .button {
      border: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-height: 310px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #BFBFBF #ebeef0;

    &__placeholder {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }


  
        /* Chrome, Edge and Safari */
        &::-webkit-scrollbar {
          width: 10px;
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #ebeef0;
        }
    
        &::-webkit-scrollbar-track:hover {
          background-color: #ebeef0;
        }
    
        &::-webkit-scrollbar-track:active {
          background-color: #ebeef0;
        }
    
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #BFBFBF;
        }
    
        &::-webkit-scrollbar-thumb:hover {
          background-color: #BFBFBF;
        }
    
        &::-webkit-scrollbar-thumb:active {
          background-color: #BFBFBF;
        }

    &__item {
      padding: 5px 0;
      display: flex;
      border-bottom: 1px solid #e1e6eb;
      text-decoration: none !important;
      position: relative;

      &__wrapper {
        display: flex;
        gap: 15px;
        padding: 10px 10px;
        border-radius: 15px;
        width: 100%;

        &:hover {
          background-color: rgba($primary-color, 0.05);
        }
      }

      &:last-child {
        border-bottom: none;
      }

      &__image {
        svg {
          height: 30px;
          width: 30px;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        flex: 1;

        span {
          margin-bottom: 3px;
          font-weight: 600;
        }

        small {
          font-size: 12px;
        }
      }

      &__status {
        display: flex;
        align-items: center;

        &::before {
          content: "";
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background-color: $primary-color;
        }
      }

      &--disabled {
        #{ $self }__content {
          &__item {
            &__info {
              span,
              small {
                color: $text-color-light;
              }
            }
          }
        }
      }
    }
  }

  &__bottom {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
