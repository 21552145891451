@import "styles/variables.scss";

.async-header {
  display: inline-grid;

  &__skeleton {
    border-radius: 20px;
    height: 30px;
    width: 150px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    background: linear-gradient(to right, #f7f9fa, #eaeaea 50%, #f7f9fa 80%),
      #f7f9fa;
    background-repeat: repeat-y;
    background-size: 50px 100%;
    background-position: -50px 0;
    animation: shimmer 1s infinite;
  }
}

@keyframes shimmer {
  to {
    background-position: calc(100% + 50px) 0;
  }
}
