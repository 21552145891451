@import "styles/variables.scss";

.tax-input {
  $self: &;

  label {
    display: block;
    margin-bottom: 12px;
    color: $text-color;
    font-size: 14px;
    font-weight: 600;

    span {
      color: $primary-color;
    }
  }

  &__content {
    height: 44px;
    position: relative;

    .select {
      position: absolute;
      top: 50%;
      left: 1px;
      transform: translateY(-50%);
      width: 130px;
      height: 42px;
      z-index: 100;

      &__options {
        width: 180px;
      }

      &--top {
        .select__options {
          bottom: calc(100% + 15px);
        }
      }

      > button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #e1e6eb;
      }
    }

    > input {
      padding: 0 15px;
      padding-left: 140px;
      border-radius: 10px;
      height: 100%;
      width: 100%;
      background-color: $color-grey-light;
      border: 1px solid $color-grey;

      &:focus {
        box-shadow: 0 0 0 4px rgba($primary-color, 0.1);
        border-color: rgba($primary-color, 0.8);
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      -moz-appearance: textfield;
    }
  }

  &--active {
    #{ $self }__content {
      border-color: red;
    }
  }

  &--error {
    #{ $self } {
      &__content {
        input {
          box-shadow: 0 0 0 4px rgba($color-red, 0.1);
          border-color: rgba($color-red, 0.8);
        }
      }

      &__error {
        display: block;
        margin-top: 5px;
        color: $color-red;
        font-size: 13px;
      }
    }
  }

  &--uppercase-label {
    label {
      text-transform: uppercase;
      letter-spacing: 2.3px;
      font-size: 11px;
    }
  }
}
