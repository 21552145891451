@import "styles/variables.scss";

.Toastify {
  &__toast {
    padding: 0 32px 0 40px;
    border-radius: 0;
    border-bottom-left-radius: 5px;
    font-size: 15px;
    min-height: 50px;

    @media (max-width: 1060px) {
      padding: 10px 32px;
      min-height: 86px;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      font-size: 16px;
    }

    &-icon {
      display: none;
    }

    &-body {
      margin-right: 16px;
    }

    &-container--top-right, &-container--top-center {
      top: 130px;
      right: -4px;
      width: fit-content;

      @media (max-width: 1300px) {
        top: 75px;
      }

      @media (max-width: 1060px) {
        top: 00px;
        left: 0;
        right: 0;
        width: 100%;
      }

      > div {
        color: #fff;
        font-weight: 500;
      }

      button {
        opacity: 1;
        align-self: unset;

        svg {
          color: #fff !important;
          opacity: 1 !important;
        }
      }
    }

    &--success {
      background: $color-green;
    }

    &--error {
      background: $color-red;
    }
  }

  &__close-button {
    margin-top: 5px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
}
