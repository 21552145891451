.radio-button {
  padding: 12px;
  padding-right: 24px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: transparent;
  border: 1px solid #E1E6EB;
  border-radius: 5px;
  width: fit-content;

  &:hover{
    background-color: #F7F9FA;
  }

  label {
    padding-left: 46px;
    display: flex;
    align-items: center;
    color: black;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    user-select: none;
    width: 100%;
    height: 100%;

  }

  &__hint{
    padding: 0;
  }

  svg {
    margin-left: 10px;
    height: 20px;
    width: 20px;
    flex-shrink: 0;

    path {
      &:first-child{
        fill: transparent;
      }
      &:last-child{
        fill: #848484;
      }
    }
  }

  input {
    display: none;

    &:checked {
      ~ .checkmark {
        background-color: #fff;
        border-color: #fff;

        &::after {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    left: 15px;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 1px solid #E1E6EB;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform ease 0.3s;
      width: 12px;
      height: 12px;
      background-color: #0F0FFF;
      border-radius: 50%;
    }
  }

  &--error {
    label {
      color: #f20b0b;
    }

    .checkmark {
      border-color: #f20b0b;
    }
  }

  &--active {
    border-color: #0F0FFF;
    background-color: #0F0FFF;

    &:hover{
      background-color: #0F0FFF;
    }

    svg {
      path {
        &:first-child{
          stroke: #fff;
        }
        &:last-child{
          fill: #fff;
        }
      }
    }

    label {
      color: #fff;
    }
  }
}
