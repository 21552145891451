.pill-grid {
  display: flex;
  flex-direction: column;
  gap: 5px;

  span {
    font-size: 14px;
    font-weight: 600;

    @media (max-width: 1060px) {
      display: none;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}
