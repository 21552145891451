.loader {
  $self: &;
  display: flex;
  align-items: center;

  &__progress {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;

    &::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 2px solid #fff;
      animation: prixClipFix 2s linear infinite;
    }
  }

  &--large {
    #{ $self }__progress {
      width: 30px;
      height: 30px;

      &::before {
        border-width: 2.7px;
      }
    }
  }

  &--center {
    margin: 0 auto;
  }

  &--blue {
    #{ $self }__progress {
      &::before {
        border-color: #2222cc;

        @media (max-width: 1060px) {
          border-color: #0F0FFF;
        }
      }
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
