@import "styles/variables.scss";

.details-card {
  $self: &;
  padding: 24px;
  background-color: #fff;
  border: 1px solid #e1e6eb;
  border-radius: 5px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &__tabs{
    margin-bottom: 24px;
  }

  &__subscription_end{
    margin: -25px -25px 24px -25px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    gap: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @media (max-width: 1060px) {
      flex-direction: column;
    }

    span{
      color: #fff;
      font-weight: 600;
    }

    > div{
      display: flex;
      align-items: center;
      gap: 20px;

      @media (max-width: 1060px) {
        flex-direction: column;
      }

      span{
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &__wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      flex: 1;

      .select{
        max-width: 120px;
      }
    }

    & > &__buttons {
      display: flex;
      gap: 18px;

      @media (max-width: 1060px) {
        display: none;
      }
    }
  }

  &__content {
    margin-top: 24px;
    position: relative;
    flex: 1;

    &__mobile-bottom{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 37px;
      width: 100%;
      overflow: hidden;

      &__wrapper{
        position: fixed;
        background-color: red;
        height: 37px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }

    &__actions-mobile{
      margin-top: 40px;
      display: none;

      @media (max-width: 1060px) {
        display: flex;
        flex-direction: column;
        gap: 10px !important;

        .details-card__header__buttons{
          display: flex;
          flex-direction: column;
          gap: 5px;

          .button{
            width: 100%;
          }
        }
      }

      > a{
        width: 100%;
      }

      &--no-margin{
        margin-top: 0;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__item {
      display: flex;
      align-items: flex-start;

      @media (max-width: 520px) {
        flex-direction: column;
        gap: 5px;
      }

      > label {
        min-width: 200px;
        font-weight: 600;
        word-break: break-word;

        @media (max-width: 1060px) {
          min-width: unset;
          width: 100%;
          line-height: 20px;
        }
      }

      > span {
        display: flex;
        gap: 5px;
        flex: 1;
        word-break: break-word;

        &.flex-end {
          justify-content: flex-end;
        }

        .tooltip {
          margin-top: -3px;
        }
      }

      &.extra-padding {
        margin-bottom: 8px;
      }

      .button {
        border: none;
      }

      .tooltip {
        &:hover {
          svg {
            path {
              &:first-of-type {
                fill: #0f0fff;
                stroke: #0f0fff;
              }

              &:last-of-type {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }

  &--error {
    border-color: $color-red;

    #{ $self }__header {
      h3 {
        color: $color-red;
      }
    }
  }

  &--verification {
    border-color: $primary-color;

    #{ $self }__header {
      h3 {
        color: $primary-color;
      }
    }
  }

  &--no_border{
    border: none;
    padding: 0;
  }
}
