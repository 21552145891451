.users-content{

  .table__row{
    &:not(:first-child){

      padding: 10px 0;
      min-height: 90px;
      height: unset;
    }
  }
}