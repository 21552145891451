.dashboard-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin: 27px 0 16px;
    text-align: center;
  }

  p {
    text-align: center;
  }

}
