@import "styles/variables.scss";

.new-website-content__choose-plan__subscription-plan {
  $self: &;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__header {
    margin-bottom: 20px;
    display: flex;

    @media (max-width: 1060px) {
      flex-direction: column;
    }

    &__item {
      display: flex;

      @media (max-width: 1060px) {
        margin-bottom: 24px;
      }

      &:nth-child(1) {
        width: 200px;
      }

      &:nth-child(2) {
        flex: 1;
        justify-content: center;

        @media (max-width: 820px) {
          margin-top: 20px;
        }
      }

      &:nth-child(3) {
        width: 200px;
      }

      &__billed-switcher {
        display: flex;
        gap: 24px;

        .switch {
          input {
            ~ .checkmark {
              background-color: #d0d0fd;
              &::after {
                background-color: $primary-color;
              }
            }
          }
        }

        span {
          display: flex;
          flex-direction: column;

          &.active {
            label {
              color: $primary-color;
              font-weight: 800;
            }
          }

          label {
            color: $text-color-light;
          }

          &:first-child {
            align-items: flex-end;

            small{
              text-align: right;
            }
          }

          small {
            font-size: 12px;
            color: $text-color-light;
            line-height: 18px;

          }
        }
      }
    }
  }

  &__content {
    display: flex;
    gap: 32px;
    flex: 1;/* Zachowuje zawartość widoczną w pionie */
    scrollbar-width: thin;
    scrollbar-color: #2222cc #ebeef0;
    padding-bottom: 10px;

    @media (max-width: 1060px) {
      gap: 16px;
    }

    @media (max-width: 900px) {
      overflow: auto;
    }

    /* Chrome, Edge and Safari */
    &::-webkit-scrollbar {
      width: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ebeef0;
    }

    &::-webkit-scrollbar-track:hover {
      background-color: #ebeef0;
    }

    &::-webkit-scrollbar-track:active {
      background-color: #ebeef0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #2222cc;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #0f0fff;
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: #0f0fff;
    }

    &--error {
      #{ $self }__content__item {
        box-shadow: 0 0 0 4px rgba($color-red, 0.1);
        border-color: rgba($color-red, 0.8);
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 24px;
      flex: 1;
      background-color: #fff;
      border: 1px solid #e1e6eb;
      border-radius: 5px;
      position: relative;

      &::after {
        content: "";
        height: 24px;
        width: 24px;
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: #f7f9fa;
        border: 1px solid #ebeef0;
        border-radius: 50%;
      }

      > svg {
        margin-bottom: 14px;
        flex-shrink: 0;

        @media (max-width: 1060px) {
          display: none;
        }
      }

      > span {
        margin-bottom: 4px;
        font-weight: 800;
      }

      > p {
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 600;
      }

      &__price {
        margin: 22px 0;
        margin-top: auto;
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: 1060px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &__info{
          display: flex;
          flex-direction: column;
        }

        h1 {
          font-size: 58px;
        }

        span {
          margin-top: 3px;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
      }

      &__features {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 24px;
        z-index: 1000;

        .tooltip {
          #{ $self }__content__item__features {
            &__item {
              display: flex;
              padding: 8px;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              background-color: $color-grey-light;
              border-radius: 5px;

              svg {
                height: 18px;
                width: 18px;
                flex-shrink: 0;

                path {
                  fill: $text-color;
                }
              }

              label {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 14px;
                min-width: 160px;
              }

              span {
                font-size: 14px;
                font-weight: 600;
                white-space: nowrap;
              }
            }
          }

          &:nth-child(2n) {
            #{ $self }__content__item__features {
              &__item {
                background-color: transparent;
              }
            }
          }
        }
      }

      &__current {
        position: absolute;
        top: 12px;
        right: 12px;
        padding: 0px 6px;
        background-color: $color-green;
        border-radius: 3px;

        span {
          text-transform: uppercase;
          font-size: 10px;
          line-height: 24px;
          color: #fff;
          font-weight: 600;
        }
      }

      .button {
        padding: 0;
        flex-shrink: 0;
        border: none;
        height: unset;
        background-color: transparent !important;
        color: #0f0fff !important;

        &:hover {
          filter: none;
          background-color: unset;
          text-decoration: underline;
        }
      }

      &:hover {
        border-color: #848484;
      }

      &--active {
        background-color: #0f0fff;

        &::after {
          content: "";
          background-image: url("/assets/icons/circle-checkmark.svg");
        }

        span,
        p {
          color: #fff;
        }

        #{ $self }__content__item {
          &__price {
            h1 {
              color: #fff;
            }
          }
        }

        #{ $self }__content__item__features {
          .tooltip {
            &__content {
              background-color: #fff;

              span {
                color: $text-color !important;
              }

              &::before {
                border-color: transparent transparent #fff transparent;
              }
            }

            #{ $self }__content__item__features {
              &__item {
                background-color: $primary-color;

                label {
                  color: #fff;

                  svg {
                    path {
                      fill: #fff;
                    }
                  }
                }
              }
            }

            &:nth-child(2n) {
              #{ $self }__content__item__features {
                &__item {
                  background-color: transparent;
                }
              }
            }
          }
        }

        .button {
          color: $color-turquoise !important;

          svg {
            path {
              fill: $color-turquoise !important;
            }
          }

          &:hover {
            filter: none;
            background-color: unset;
          }
        }
      }

      &--current {
        pointer-events: none;

        &::after {
          content: "";
          display: none;
        }
      }
    }
  }
}
