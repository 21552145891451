@import "styles/variables.scss";

.webmaster-modal {
  $self: &;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__item {
    display: flex;
    align-items: center;
    gap: 10px;

    &__image {
      height: 32px;
      width: 32px;
      position: relative;

      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    &--active {
      #{ $self }__item__image {
        &::before {
          content: "";
          border: 2px solid $primary-color;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          height: 37px;
          width: 37px;
        }
      }
    }
  }
}
