@import "styles/variables.scss";

.details-card-webmaster-time {
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;

  &__content{
    display: flex;
    align-items: center;
    gap: 10px;

    &__image {
      height: 32px;
      width: 32px;
      overflow: hidden;
      border-radius: 50%;
  
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &--over{
    color: red;
  }


}
