@import "styles/variables.scss";

.dropdown-card {
  padding: 8px;
  background-color: #ffffff;
  border-radius: 20px;
  filter: drop-shadow(1px 0px 0px $color-grey)
    drop-shadow(-1px 0px 0px $color-grey) drop-shadow(0px 1px 0px $color-grey)
    drop-shadow(0px -1px 0px $color-grey);
  box-shadow: 0px 4px 6px -3px rgba(51, 51, 51, 0.15);
  position: relative;

  @media (max-width: 560px) {
    border-radius: 0;
  }

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0px;
    right: 7px;
    box-sizing: border-box;
    z-index: 5;
    border: 5px solid black;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(135deg);

    @media (max-width: 560px) {
      display: none;
    }
  }

  &__wrapper {
    padding: 24px 32px;
    border-radius: 14px;
    background-color: $color-grey-light;
  }

  &--left {
    &::before {
      right: unset;
      left: 30px;
    }
  }
}
