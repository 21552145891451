@import "styles/variables.scss";

.task-conversation__details__list{

    &__item{
        width: fit-content;

        &__wrapper{
            display: flex;
            flex-direction: column;
            gap: 24px;

            &--highlighted{
                padding: 12px 24px;
                background-color: rgba(#0F0FFF, 0.05);
                border: 1px solid rgba(#0F0FFF,0.3);
                border-radius: 5px;
            }
        }

        span{
            white-space: pre-wrap;
        }

        &--link{
            span{
                color: #2222cc;
                font-weight: 600;
                cursor: pointer;

                &:hover{
                    text-decoration: underline;
                }
            }
        }

        &__list{
            display: flex;
            flex-direction: column;

            &__entry{
                padding: 12px 0;
                border-bottom: 1px solid #E1E6EB;

                &:first-child{
                    padding-top: 0;
                }

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }

                a{
                    color: #2222cc;
                }

                &--over{
                    color: red;
                }
            }
        }

        &--entries{
            width: 100%;

            @media (max-width: 1060px) {
                strong{
                    display: none;
                }
            }
        }

        &--error{
            span{
                color: red;
            }
        }

    }
}