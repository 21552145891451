@import "styles/variables.scss";

.dropdown-menu {
  $self: &;

  &__wrapper {
    gap: 24px;
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    color: $text-color;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }

    svg {
      margin-right: 10px;
      height: 18px;
      width: 18px;
    }
  }
}
