@import "styles/variables.scss";

.new-website-content__your-data {
  $self: &;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &__wrapper {
    display: flex;
    gap: 32px;

    @media (max-width: 820px) {
      flex-direction: column;
      gap: 0px;
    }
  }

  &__form {
    flex: 1;

    @media (max-width: 820px) {
      margin-bottom: 50px;

      &:nth-child(2) {
        margin-bottom: 18px;
      }

      &:last-child {
        #{ $self }__form__header {
          display: none;
        }
      }
    }

    &__header {
      height: 32px;
      margin-bottom: 18px;
    }
  }

  &__able-top-change-info {
    margin-top: 24px;
    margin-bottom: auto;

    small {
      color: #848484;
    }
  }

  &__bottom {
    padding: 10px 0;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 100;

    &--mobile{
      display: flex;
      gap: 16px;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 96px;
      background-color: #fff;
      padding: 10px 16px;

      .button{
        flex: 1;
      }
    }
  }
}
