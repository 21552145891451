@import "styles/variables.scss";

.avatar {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 1060px) {
    pointer-events: none;
  }

  &__photo{
    overflow: hidden;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;

    @media (max-width: 1060px) {
      height: 56px;
      width: 56px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  span{
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }
}
