.contact-content {
  display: flex;
  max-width: 1200px;
  width: 100%;
  gap: 32px;

  .details-card{
    position: relative;

    .loader{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  @media (max-width: 1060px) {
    .calendly-inline-widget{
      height: 420px !important;
    }
  }

  @media (max-width: 980px) {
    flex-direction: column;
  }
}
