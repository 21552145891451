@import "styles/variables.scss";

.new-website-content__check-your-data {
  $self: &;
  display: flex;
  flex-direction: column;

  &__wrapper {
    display: flex;
    gap: 32px;

    @media (max-width: 980px) {
      flex-direction: column;
    }
  }

  &__form {
    flex: 1;
    max-width: 560px;
    gap: 32px;

    @media (max-width: 980px) {
      max-width: unset;
    }

    &:last-child {
      max-width: 600px;

      @media (max-width: 980px) {
        max-width: unset;

        #{ $self }__form__header {
          display: none;
        }
      }
    }

    &__header {
      height: 32px;
      margin-bottom: 18px;
    }

    &__able-to-change-info {
      margin-top: -8px;

      small {
        color: #848484;
      }
    }
  }

  &__bottom {
    padding: 10px 0;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 10;
  }
}
