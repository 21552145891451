.faq-content {
  display: flex;
  gap: 32px;
  max-width: 1380px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  @media (max-width: 930px) {
    gap: 24px;
  }

  .details-card__content{
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (max-width: 930px) {
      gap: 10px;
    }
  }

  &__item{
    background-color: #F7F9FA;
    padding: 16px;
    border-radius: 5px;
    text-align: left;

    &:hover{
      background-color: #E1E6EB;
    }

    span{
      font-weight: 600;
    }
  }
}
