@import "styles/variables.scss";

.progress-bar-steps {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media (max-width: 1060px) {
    margin-bottom: 24px;
  }

  &__steps{
    display: flex;
  }

  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    small {
      font-weight: 600;
      color: $text-color-light;

      @media (max-width: 560px) {
        display: none;
      }
    }

    &::before {
      content: "";
      height: 8px;
      width: 100%;
      //   background-color: $primary-color;
      background-color: $color-grey;
    }

    &:first-child {
      &::before {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    &:last-child {
      &::before {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    &--active {
      small {
        color: $primary-color;
      }

      &::before {
        background-color: $primary-color;
      }
    }

    &--finished {

      small {
        color: $color-purple;
      }

      &::before {
        background-color: $color-purple;
      }
    }

    &--touched {
      cursor: pointer;

    }
  }

  &__info{
    margin-top: 16px;
    color: #0F0FFF;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    align-self: flex-end;
  }
}
