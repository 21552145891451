@import "styles/variables.scss";

.language-modal {
  $self: &;
  display: flex;
  align-items: center;
  gap: 30px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 10px;

    &__icon {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      position: relative;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    &--active {
      #{ $self }__item__icon {
        &::before {
          content: "";
          border: 2px solid $primary-color;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          height: 43px;
          width: 43px;
        }
      }
    }
  }
}
