.task-conversation__chat {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  min-width: 500px;

  @media (max-width: 1060px) {
    min-width: 100%;
  }

  &__content {
    padding-right: 10px;
    width: 100%;
    height: 100%;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #BFBFBF #ebeef0;

    @media (max-width: 1060px) {
      padding-right: 0;
    }
  
        /* Chrome, Edge and Safari */
        &::-webkit-scrollbar {
          width: 10px;
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #ebeef0;
        }
    
        &::-webkit-scrollbar-track:hover {
          background-color: #ebeef0;
        }
    
        &::-webkit-scrollbar-track:active {
          background-color: #ebeef0;
        }
    
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #BFBFBF;
        }
    
        &::-webkit-scrollbar-thumb:hover {
          background-color: #BFBFBF;
        }
    
        &::-webkit-scrollbar-thumb:active {
          background-color: #BFBFBF;
        }

    &__wrapper {
      padding-bottom: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
