@import "styles/variables.scss";

html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
}

#root {
  min-height: 100%;
  width: 100%;
}

.empty-layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__top {
    position: absolute;
    top: 0;
    left: 0;
    padding: 40px;

    @media (max-width: 930px) {
      padding: 25px;
    }

    a {
      display: flex;

      svg {
        width: 173px;
        height: 34px;

        @media (max-width: 930px) {
          width: 155px;
          height: 30px;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1;

    .auth-content {
      padding: 80px 0 60px;

      @media (max-width: 520px) {
        padding: 120px 0 60px;
      }

      &__wrapper {
        margin: 0 auto;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          text-align: center;
        }
      }

      &__header {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
          margin-bottom: 16px;
          text-align: center;
        }

        span {
          max-width: 384px;
          width: 100%;
        }
      }

      &__form {
        margin-top: 64px;
        max-width: 384px;
        width: 100%;

        &__group {
          display: flex;
          gap: 24px;

          > div {
            flex: 1;
          }
        }

        &__inputs {
          .input {
            margin-bottom: 24px;

            &:last-of-type {
              margin-bottom: 16px;
            }
          }
        }

        &__forget-password {
          display: flex;
          justify-content: flex-end;

          a {
            width: fit-content;
            color: #2222cc;
            font-size: 12px;
            font-weight: 600;
          }
        }

        .password-validator {
          margin: 16px 0 25px;
        }

        &__required-info {
          small {
            color: #848484;
          }

          span {
            color: #0f0fff;
          }
        }

        &__actions {
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;

          span {
            a {
              color: #2222cc;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
