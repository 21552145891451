@import "styles/variables.scss";

.button {
  $self: &;
  padding: 0 35px;
  background-color: $primary-color;
  color: #fff;
  height: 43px;
  border-radius: 25px;
  font-weight: 600;
  width: fit-content;
  transition: filter ease 0.2s, background-color ease 0.2s, color ease 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 28px;
  vertical-align: baseline;
  white-space: nowrap;

  @media (max-width: 1060px) {
    height: 50px !important;
    background-color: #0F0FFF !important;
    color: #fff !important;
    padding: 0 15px;
  }

  svg {
    margin-left: 10px;
    height: 12px;
    width: 12px;

    path {
      fill: #fff;
    }
  }

  &:hover {
    filter: brightness(1.2);
    text-decoration: none;
  }

  &--full-width {
    width: 100%;
  }

  &--transparent {
    background-color: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;

    .loader__progress {
      &::before {
        border-color: $primary-color;
      }
    }

    svg {
      path {
        fill: $primary-color;
      }
    }

    &:hover {
      filter: brightness(1);
      background-color: rgba($primary-color, 0.15);
    }
  }

  &--mobile-transparent{
    background-color: rgba(#0F0FFF, 0.15) !important;
    border: 1px solid $primary-color !important;
    color: #2222CC !important;
  }

  &--no-border {
    background-color: transparent;
    border: none;
    color: $primary-color;

    .loader__progress {
      &::before {
        border-color: $primary-color;
      }
    }

    svg {
      path {
        fill: $primary-color;
      }
    }

    &:hover {
      filter: brightness(1);
      background-color: rgba($primary-color, 0.15);
    }
  }

  &--no-border-grey {
    background-color: transparent;
    border: none;
    color: #d3d3d3;

    @media (max-width: 1060px) {
      background-color: transparent !important;
      color: #D3D3D3 !important;
    }

    .loader__progress {
      &::before {
        border-color: $primary-color;
      }
    }

    svg {
      path {
        fill: $primary-color;
      }
    }

    &:hover {
      color: #ff0000;
    }
  }

  &--no-border-error {
    background-color: transparent;
    border: none;
    color: #ff0000;
    pointer-events: none;

    .loader__progress {
      &::before {
        border-color: $primary-color;
      }
    }

    svg {
      path {
        fill: $primary-color;
      }
    }
  }

  &--grey {
    background-color: #e1e6eb;
    color: $text-color;

    &:hover {
      filter: brightness(1.03);
    }
  }

  &--active {
    background-color: $primary-color;
    color: #fff !important;

    &:hover {
      background-color: $primary-color;
      color: #fff !important;
    }
  }

  &--icon-button {
    padding: 0;
    width: 15px;
    height: 15px;
    position: relative;
    background-color: transparent;

    svg {
      margin-right: 0;
      height: 15px;
      width: 15px;
    }

    &:hover {
      &::before {
        content: "";
        background-color: #2b2b34;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 32px;
        width: 32px;
        z-index: -1;
        border-radius: 7px;
      }
    }
  }

  &--disabled {
    cursor: auto;
    background-color: #3a3a3e;
    color: #6a6a74;

    &:hover {
      filter: none;
    }
  }

  &--small {
    padding: 0 24px;
    height: 40px;
    font-size: 12px;
  }

  &--mobile-small {
    padding: 0 24px;
    height: 40px !important;
    font-size: 12px;
  }

  &--large {
    padding: 0 64px;
    height: 58px;
    font-size: 16px;
    border-radius: 31px;
  }

  &--success {
    background-color: #00d621 !important;
  }

  &--error {
    background-color: #E30D0D !important;
  }

  &.half-margin{
    @media (max-width: 1060px) {
      margin-top: 24px;
    }
  }
}
