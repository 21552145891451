.dashboard-overview {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 16px;
    background-color: #f7f9fa;

    span {
      width: 100%;

      > span {
        color: #ff0000;
      }

      &:first-child {
        // max-width: 150px;
        font-weight: 600;
      }

      &:last-child {
        max-width: 160px;
      }
    }
  }
}
