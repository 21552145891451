.task-conversation__details__list{

    &__item{
        width: fit-content;

        span{
            white-space: pre-wrap;
        }

        &--link{
            span{
                color: #2222cc;
                font-weight: 600;
                cursor: pointer;

                &:hover{
                    text-decoration: underline;
                }
            }
        }

        &__list{
            display: flex;
            flex-direction: column;

            &__entry{
                padding: 12px 0;
                border-bottom: 1px solid #E1E6EB;

                &:first-child{
                    padding-top: 0;
                }

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }

                a{
                    color: #2222cc;
                }
            }
        }

        &--entries{
            width: 100%;
        }
    }
}