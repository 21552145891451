.webmaster-float-tracker {
  padding: 16px;
  width: 240px;
  background-color: #0f0fff;
  position: fixed;
  left: 20px;
  bottom: 20px;
  border-radius: 5px;
  transform: translateX(-110%);
  transition: transform ease 0.3s;

  .tracker-button {
    span {
      display: none;
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      color: #fff;
    }
  }

  @media (max-width: 1300px) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;
    padding: 5px 10px;
    z-index: 1000;

    .tracker-button {
      span {
        display: flex;
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1300px) {
      display: none;
    }

    span {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
  }

  p {
    margin: 16px 0;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }

  &__tracker {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > span {
      display: flex;
      gap: 8px;

      @media (max-width: 1300px) {
        display: none;
      }

      span {
        margin-top: 3px;
        color: #fff;
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
      }
    }
  }

  &--active {
    transform: translateY(0%);
  }
}
