.password-validator {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  row-gap: 10px;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 8px;
  }

  &__item {
    flex: 40%;
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      line-height: 22px;
    }

    &::before {
      content: "";
      margin-right: 8px;
      height: 12px;
      width: 12px;
      background-color: red;
      border-radius: 50%;
    }

    &--success {
      &::before {
        background-color: #00d621;
      }
    }

    &--failed {
      &::before {
        background-color: #ff0000;
      }
    }
  }
}
