.faq-content-modal{
  display: flex;
  flex-direction: column;

  > small{
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 600;
  }

  > h3{
    margin-bottom: 32px;
  }

  p{
    white-space: pre-wrap;
    word-break: break-word;
    font-weight: 300;

    strong{
      font-weight: 700;
    }
  }
}
