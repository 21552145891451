/* ---=== COLORS ===--- */
$primary-bg: #fff;

$primary-color: #2222cc;
$text-color: #333333;
$text-color-light: #848484;

$color-turquoise: #14e0e0;
$color-purple: #9734eb;
$color-green: #00d621;
$color-red: #ff0000;
$color-grey: #ebeef0;
$color-grey-light: #f7f9fa;
