@import "styles/variables.scss";

.dashboard-development-time {
  &__progress {
    display: flex;
    height: 54px;
    width: 100%;

    &__part {
      height: 100%;

      &:nth-child(2) {
        background-color: #9734eb;
      }
    }
  }

  &__legend {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__item {
      display: flex;
      align-items: center;
      
      &__wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        span{
          flex: 1;
        }

        a{
          color: $primary-color;
        }
      }

      strong {
        font-weight: 600;
      }

      &::before {
        content: "";
        margin-right: 8px;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        flex-shrink: 0;
      }

      &:nth-child(1) {
        &::before {
          background: rgb(15, 15, 255);
          background: linear-gradient(
            90deg,
            rgba(15, 15, 255, 1) 0%,
            rgba(15, 15, 255, 1) 50%,
            rgba(0, 254, 255, 1) 50%,
            rgba(0, 254, 255, 1) 100%
          );
        }
      }

      &:nth-child(2) {
        &::before {
          background-color: #0f0fff;
        }
      }

      &:nth-child(3) {
        &::before {
          background-color: #00feff;
        }
      }

      &:nth-child(4) {
        &::before {
          background-color: #9734eb;
        }
      }
    }
  }
}
