.dropdown {
  $self: &;
  position: relative;

  @media (max-width: 560px) {
    position: unset;
  }

  &__button {
    display: flex;
  }

  &__content {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    z-index: 10;

    @media (max-width: 560px) {
      position: fixed;
      left: -15px;
      right: 0;
      top: 65px;
      bottom: 0;

      .notification-dropdown {
        width: 100%;
      }
    }
  }

  &--top {
    #{ $self }__content {
      bottom: calc(100% + 15px);
      top: unset;
    }
  }

  &--bottom {
    #{ $self }__content {
      top: calc(100% + 15px);
      bottom: unset;
    }
  }

  &--left {
    #{ $self }__content {
      left: 0;
      right: unset;
    }
  }

  &--right {
    #{ $self }__content {
      right: 0;
      left: unset;
    }
  }

  &--full-width-content {
    #{ $self }__content {
      width: 100%;
    }
  }
}
