.time-over-content {
  &__mobile_menu {
    margin-bottom: 25px;
    display: none;
    gap: 15px;

    @media (max-width: 1080px) {
      display: flex;
    }
  }

  &__content {
    display: flex;
    gap: 32px;
    height: 100%;

    @media (max-width: 1080px) {
      flex-direction: column;
      width: 100%;
    }

    &__details{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 568px;
      gap: 20px;

      @media (max-width: 1080px) {
        width: 100%;
      }
      
    }
  }

  &--overview {
    @media (max-width: 1080px) {
      .task-conversation__details {
        display: block;
        max-width: unset;
        width: 100%;
      }

      .task-conversation__chat {
        display: none;
      }
    }
  }

  &--chat {
    @media (max-width: 1080px) {
      .task-conversation__content__details {
        display: none;
      }

      .task-conversation__chat {
        display: flex;
      }
    }
  }
}
